import React from 'react';
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
    Dialog,
    DialogProps,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import ModalTitle from '../ModalTitle/ModalTitle';
import { useForm, TValidations } from '../../../../hooks/useForm';

import { ModalsEnum } from '../../../../types/Modal';
import { Quizzes } from '../../../../types/Quizzes';
const styles = (theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: 15,
            paddingTop: 12,
            paddingBottom: 22,
            paddingLeft: 16,
            paddingRight: 16,
            maxWidth: 520,
            backgroundColor: theme.backgroundColors?.primary
        },
        dialog: {
            borderRadius: 15,
            paddingLeft: theme.spacing(14),
            paddingRight: theme.spacing(14),
            paddingTop: theme.spacing(9),
            paddingBottom: theme.spacing(9)
        },
        dialogHeder: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: theme.spacing(1),
            '& > button': {
                alignSelf: 'end'
            }
        },
        dialogTitle: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginTop: theme.spacing(1)
        },
        dialogContent: {
            padding: theme.spacing(3)
        },
        dialogActions: {
            flexDirection: 'column',
            textAlign: 'center',
            '& #create-update-user-disclaimer': {
                color: theme.text?.secondary,
                fontSize: '0.8rem',
                maxWidth: 413,
                marginTop: theme.spacing(2)
            }
        },
        button: {
            width: 220,
            height: 50
        },
        inputLable: {
            marginBottom: theme.spacing(2)
        },
        inputError: {
            color: theme.palette.error.main
        }
    });
type TMode = ModalsEnum.CREATE_CATEGORY | ModalsEnum.UPDATE_CATEGORY;
type TFormFields = Quizzes.TCategory;

type TLabelsMap = {
    title: string;
    submitButton: string;
};

interface Props extends WithStyles<typeof styles>, Omit<DialogProps, 'classes'> {
    mode: TMode;
    data?: Quizzes.TCategory;
    onSubmitModal: (data: Quizzes.TCategory) => void;
    onDismiss: () => void;
}

const labels: Record<TMode, TLabelsMap> = {
    [ModalsEnum.CREATE_CATEGORY]: {
        title: 'Create New Category',
        submitButton: 'Create New Category'
    },
    [ModalsEnum.UPDATE_CATEGORY]: {
        title: 'Update Category',
        submitButton: 'Update Category'
    }
};

const initialState = {
    id: '',
    name: ''
};

const validations: TValidations<TFormFields> = {
    id: {
        required: false
    },
    name: {
        required: true
    }
};

const CategoryModal: React.FC<Props> = ({
    classes,
    open,
    data,
    mode,
    onSubmitModal,
    onDismiss
}) => {
    const { values, errors, handleInput, isFormValid, setInitalValues } = useForm<TFormFields>(
        initialState,
        validations,
        open ? data : undefined
    );

    const onClose = () => {
        setInitalValues();
        onDismiss();
    };
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setInitalValues();
        onSubmitModal(values);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="create-update-category-title"
            aria-describedby="create-update-category-disclaimer"
            PaperProps={{
                classes: {
                    root: classes.paper
                }
            }}
        >
            <ModalTitle
                titleId="create-update-category-title"
                title={labels[mode].title}
                handleClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Grid container component="form" noValidate autoComplete="off" onSubmit={onSubmit}>
                    <InputLabel
                        htmlFor="create-update-category-name"
                        className={classes.inputLable}
                    >
                    </InputLabel>
                    <OutlinedInput
                        id="create-update-category-name"
                        name="name"
                        fullWidth
                        placeholder="New category Name"
                        value={values.name}
                        onChange={handleInput}
                    />
                    {errors.name && <div className={classes.inputError}>{errors.name}</div>}
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={onSubmit}
                    color="primary"
                    variant="contained"
                    disabled={!isFormValid()}
                >
                    {labels[mode].submitButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(CategoryModal);
