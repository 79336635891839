import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  isDisabled?: boolean;
};

export const useOutlinedStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    fontWeight: 500,
    border: '1px solid #dddddd',
    overflow: 'hidden',
    borderRadius: 10,
    backgroundColor: theme.backgroundColors?.white,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&.invalid': {
      border: '1px solid #E01E4A'
    },
    '&:hover': {
      backgroundColor: (props) => {
        return props.isDisabled ? 'default' : theme.backgroundColors?.white
      }
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0 0 10px 0 rgba(0,0,0,0.17)',
      backgroundColor: theme.backgroundColors?.secondaryBlue
    },
    '& .MuiSelect-icon': {
      display: 'none'
    },
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 12,
      '& img': {
        width: 16,
        height: 16,
        marginRight: theme.spacing(1),
        objectFit: 'contain'
      },
      '& h5': {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1,
        letterSpacing: 0.38,
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& p': {
        display: 'none'
      },
    },
    '& textarea': {
      minHeight: 300
    }
  }
}));