import { IAppState } from 'types';
import { createSelector } from 'reselect';

const meetingsLoaderSelector = (state: IAppState) => Boolean(state.ui.loaders['meetings-list']);
const meetingsSelector = (state: IAppState) => ({
  loading: state.meetings.loading,
  meeting: state.meetings.meeting,
  elements: state.meetings.elements,
  totalElementsCount: state.meetings.totalElementsCount,
  totalPagesCount: state.meetings.totalPagesCount,
  rsvpSent: state.meetings.rsvpSent,
  heroImage: state.meetings.heroImage,
  logoImage: state.meetings.logoImage,
  selectedMeeting: state.meetings.selectedMeeting
});

export const meetingsListSelector = createSelector(
  [meetingsLoaderSelector, meetingsSelector],
  (loadingMeetings, {
    loading, meeting, elements, totalElementsCount, totalPagesCount, rsvpSent, heroImage, logoImage, selectedMeeting
  }) => {
    return {
      loadingMeetings,
      loading,
      meeting,
      elements,
      totalElementsCount,
      totalPagesCount,
      rsvpSent,
      heroImage,
      logoImage,
      selectedMeeting
    }
  }
);