import { Meetings, MeetingsActions } from 'types/Meetings';
import { PAGE_SIZE } from '../constants';

export { getAllMeetings } from './actions';
export { getMeeting } from './actions';
export { updateMeeting } from './actions';
export { createMeeting } from './actions';
export { createDefaultMeeting } from './actions';
export { deleteMeeting } from './actions';
export { getMeetingByHost } from './actions';
export { respondInvitation } from './actions';
export { setMeetings } from './actions';
export { setMeeting } from './actions';
export { setMeetingQueryParams } from './actions';

const initialState: Meetings.TState = {
  loading: false,
  elements: [],
  totalElementsCount: 0,
  totalPagesCount: 0,
  meeting: null,
  rsvpSent: {
    id: null,
    sent: false
  },
  heroImage: null,
  logoImage: null,
  selectedMeeting: {
    page: 1,
    index: 0
  }
};

const deleteMeetingReducer = (id: string, state: Meetings.TState): Meetings.TState => {
  const elements = state.elements.filter(e => e.id !== id);
  const total = elements.length;
  const pageCount = (total - total % PAGE_SIZE) / PAGE_SIZE;
  const lastIndex = (state.selectedMeeting.page - 1) * PAGE_SIZE + state.selectedMeeting.index;
  const selected = state.selectedMeeting;

  if (lastIndex >= total) {
    selected.index -= 1;
    if (selected.index < 0) {
      selected.index = 0;
      selected.page -= 1;
      if (selected.page < 1) {
        selected.page = 1;
      }
    }
  }

  return {
    ...state,
    elements: elements,
    totalElementsCount: total,
    totalPagesCount: pageCount,
    selectedMeeting: selected
  };
}

const putGameReducer = (data: Meetings.TMeeting, state: Meetings.TState): Meetings.TState => {
  let elemIndex = -1;
  for (let index = 0; index < state.elements.length; index += 1) {
    if (state.elements[index].id === data.id) {
      elemIndex = index;
      break;
    }
  }

  const elements = state.elements;

  if (elemIndex !== -1) {
    elements[elemIndex] = data;
  }

  return {
    ...state,
    elements
  };
}

const meetingsReducer = (
  state = initialState,
  action: Meetings.TActions.AllReducer
): Meetings.TState => {
  switch (action.type) {
    case MeetingsActions.SET:
      return {
        ...state,
        meeting: null,
        elements: action.payload.elements,
        totalElementsCount: action.payload.totalElementsCount,
        totalPagesCount: action.payload.totalPagesCount,
        rsvpSent: {
          id: null,
          sent: false
        },
        heroImage: null,
        logoImage: null
      };
    case MeetingsActions.SET_ONE:
      return {
        ...state,
        meeting: action.payload
      };
    case MeetingsActions.SET_SENT_RSVP:
      return {
        ...state,
        rsvpSent: {
          id: action.payload.id,
          sent: action.payload.sent
        }
      };
    case MeetingsActions.SET_HERO_IMAGE:
      return {
        ...state,
        heroImage: action.payload
      };
    case MeetingsActions.SET_LOGO_IMAGE:
      return {
        ...state,
        logoImage: action.payload
      };
    case MeetingsActions.SET_SELECTED_MEETING:
      return {
        ...state,
        selectedMeeting: {
          page: action.payload.page,
          index: action.payload.index
        }
      };
    case MeetingsActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case MeetingsActions.DELETE_COMPLETE:
      return deleteMeetingReducer(action.payload, state);
    case MeetingsActions.PUT_GAME_COMPLETE:
      return putGameReducer(action.payload, state);
    default:
      return state;
  }
};

export default meetingsReducer;
export { meetingsListSelector } from './selectors';
