import { Modal, ModalActions } from '../../types/Modal';

const initialState: Modal.TState = {
    opened: null,
    data: {}
};

const modalReducer = (state = initialState, action: Modal.TActions.AllReducer): Modal.TState => {
    switch (action.type) {
        case ModalActions.OPEN:
            return { ...state, ...action.payload };
        case ModalActions.CLOSE:
            return initialState;
        default:
            return state;
    }
};

export default modalReducer;
