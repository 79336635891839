import { TypeOfProp } from '../../types/utils';
import { UI, SnackbarActions, LoadersActions, NavigationActions } from '../../types/UI';

/** Snackbar Redux Action */
export const showSnackbar = (
    data: TypeOfProp<UI.TState, 'snackbar'>
): UI.TActions.Snackbar.Show => ({
    type: SnackbarActions.SHOW,
    payload: data
});

/** Snackbar Redux Action */
export const hideSnackbar = (): UI.TActions.Snackbar.Hide => ({
    type: SnackbarActions.HIDE
});

/** Loaders Redux Action */
export const showLoader = (key: UI.TLoaders): UI.TActions.Loaders.Show => ({
    type: LoadersActions.SHOW,
    payload: key
});

/** Loaders Redux Action */
export const hideLoader = (key: UI.TLoaders): UI.TActions.Loaders.Hide => ({
    type: LoadersActions.HIDE,
    payload: key
});

/** Navigation Redux Action */
export const navGoTo = (route: UI.TRoute): UI.TActions.Navigation.GoTo => ({
    type: NavigationActions.GO_TO,
    payload: route
});

export const navClearStore = (): UI.TActions.Navigation.Clear => ({
    type: NavigationActions.CLEAR
});
