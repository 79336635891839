import {Routes} from "./Routes";

class RouteBuilder {

    private route : string = ""

    get login(){
        this.route += Routes.LOGIN
        return this
    }

    get changePassword(){
        this.route += Routes.CHANGE_PASSWORD
        return this
    }

    get users(){
        this.route += Routes.USERS
        return this
    }

    get quiz(){
        this.route += Routes.QUIZ
        return this
    }

    get huddle(){
        this.route += Routes.HUDDLE
        return this
    }

    get all(){
        this.route += Routes.ALL
        return this
    }

    get manage(){
        this.route += Routes.MANAGE
        return this
    }

    get categories(){
        this.route += Routes.CATEGORIES
        return this
    }

    get scheduler(){
        this.route += Routes.SCHEDULER
        return this
    }

    get calendar(){
        this.route += Routes.CALENDAR
        return this
    }

    get newMeeting(){
        this.route += Routes.NEW_MEETING
        return this
    }

    get newEvent(){
        this.route += Routes.NEW_EVENT
        return this
    }

    get editEvent(){
        this.route += Routes.EDIT_EVENT
        return this
    }

    get previewInvitation(){
        this.route += Routes.PREVIEW_INVITATION
        return this
    }

    get invitation() {
        this.route += Routes.INVITATION
        return this
    }

    get id(){
        this.route += Routes.ID
        return this
    }

    add(route : string){
        this.route += `/${route}`
        return this
    }

    build(){
        const route = this.route
        this.route = ""
        return route
    }

}

export default RouteBuilder