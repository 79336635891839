import { RolesEnum, AuthTypesEmun, ShowType } from './Enums';
import { IAction, IActionPayload } from './utils';

/** -> Authentication Action Types */
export const AuthActions = AuthTypesEmun;

export declare namespace Auth {
    export type TUser = {
        id: string;
        firstName: string;
        lastName: string;
        companyId: string;
        userName: string;
        email: string;
        roles: RolesEnum;
    };

    export type TAuthAPIResponse = {
        user: TUser;
        accessToken: string;
        accessTokenExpiresOn: string;
        community: ShowType;
        normalPermission: TPermission;
        huddlePermission: TPermission;
    };

    export type TCredentials = {
        email: string;
        password: string;
    };

    export type TSSO = {
        ssoId: string | null | undefined;
        email: string;
        firstName: string | null | undefined;
        lastName: string | null | undefined;
        community: ShowType;
    };

    export type TChangePassPayload = {
        currentPassword: string;
        newPassword: string;
    };

    export type TPermission = {
        canRead: boolean;
        canEdit: boolean;
        canCreate: boolean;
        canDelete: boolean;
        canEditOwn: boolean;
        canDeleteOwn: boolean;
    };

    export type TState = {
        user: TUser | null;
        accessTokenExpiresOn: string;
        community: ShowType;
        normalPermission: TPermission;
        huddlePermission: TPermission;
    };

    export namespace TActions {
        export type Login = IActionPayload<AuthTypesEmun.LOGIN, TCredentials>;
        export type LoginSuccess = IActionPayload<AuthTypesEmun.SUCCESS, TAuthAPIResponse>;
        export type RefreshToken = IAction<AuthTypesEmun.REFRESH_TOKEN>;
        export type Logout = IAction<AuthTypesEmun.LOGOUT>;
        export type Clear = IAction<AuthTypesEmun.CLEAR>;
        export type ChangePassword = IActionPayload<
            AuthTypesEmun.CHANGE_PASSWORD,
            TChangePassPayload
        >;
        export type SSOLogin = IActionPayload<AuthTypesEmun.SSO_LOGIN, TSSO>;

        export type AllReducer = LoginSuccess | Clear;
        export type APIActions = Login | RefreshToken | Logout | ChangePassword | SSOLogin;
    }
}
