import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  previewContainer: {
    position: 'relative',
    borderRadius: 0,
    backgroundColor: theme.backgroundColors?.white,
    boxShadow: 'none',
    minWidth: '70%',
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 40,
    marginBottom: 40
  },
  showContainer: {
    position: 'relative',
    borderRadius: 15,
    backgroundColor: theme.backgroundColors?.white,
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.15)',
    minWidth: '70%',
    marginTop: 100,
    marginBottom: 100
  },
  heroContainer: {
    position: 'relative',
    height: 0,
    paddingBottom: 'calc(33%)'
  },
  heroPreview: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.backgroundColors?.primaryGrey,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    '& p': {
      textAlign: 'center',
      fontSize: 12,
      fontStyle: 'italic',
      letterSpacing: 0.13,
      lineHeight: 1.5,
      marginTop: theme.spacing(2),
      color: theme.text?.secondary
    }
  },
  eventPreview: {
    padding: '50px 70px',
    maxWidth: 740,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logoPreview: {
    width: 120,
    height: 40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    marginBottom: theme.spacing(2)
  },
  eventDetailPreview: {
    '& h1': {
      fontSize: 26,
      fontWeight: 'bold',
      letterSpacing: 0.76,
      lineHeight: 1.5,
      color: theme.text?.black,
      marginBottom: 18
    },
    '& p': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      color: theme.text?.primary,
      '& svg': {
        color: theme.text?.primaryBlue,
        marginRight: 12
      }
    }
  },
  invitePreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingBottom: 24,
    marginTop: 24,
    marginBottom: 24,
    borderTop: '1px solid rgba(66,95,187,0.5)',
    borderBottom: '1px solid rgba(66,95,187,0.5)',
    '& p ~ p': {
      fontWeight: 'bold'
    }
  },
  providerPreview: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: -70,
    '& >p': {
      fontSize: 9,
      fontWeight: 300,
      letterSpacing: 0.02,
      lineHeight: 1.5,
      textAlign: 'center',
      marginBottom: 6,
      color: '#979797'
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: 6
      },
      '& p': {
        fontSize: 10,
        letterSpacing: 2,
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }
    }
  },
  rsvpEvent: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    padding: '8px 36px',
    borderRadius: 10,
    backgroundColor: '#00D267',
    color: 'white'
  },
  eventStartButton: {
    width: 200
  },
  addCalendarRoot: {
    margin: 0,
    marginLeft: 8,
    position: 'relative'
  },
  addCalendarWrapper: {},
  calendarDropdown: {
    position: 'absolute',
    border: '1px solid #E4E4E4',
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 9px 0 rgba(0,0,0,0.2)',
    width: 'max-content',
    '& ul': {
      listStyleType: 'none',
      paddingLeft: 12,
      paddingRight: 12,
      '& a': {
        fontSize: 13,
        fontWeight: 500,
        letterSpacing: 0.38,
        lineHeight: 2,
        textDecoration: 'none',
        color: '#425FBB'
      }
    },
    zIndex: 1
  },
  calButton: {
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: 0.03,
    lineHeight: 2,
    color: '#425FBB'
  }
}))