import AuthApi  from "../../Api/AuthApi";

class AuthService {
    constructor( private authApi : AuthApi) {
        this.api = this.api.bind(this)
    }

    api(){
        return this.authApi
    }

}

export default AuthService