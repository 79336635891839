import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    outline: 'none',
    width: 540,
    borderRadius: 13,
    backgroundColor: '#FAFAFA',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(6),
    '& h2': {
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: 0.07,
      lineHeight: 1.5,
      marginBottom: theme.spacing(1),
      color: theme.text?.black
    },
    '& p': {
      fontSize: 13,
      fontWeight: 500,
      letterSpacing: 0.38,
      lineHeight: 1.5,
      color: theme.backgroundColors?.secondaryGrey,
      marginBottom: theme.spacing(5)
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      padding: theme.spacing(8, 6),
      margin: "auto"
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    '& button': {
      width: 200
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 20
  }
}))