/** -> Scheduler Action Types */
export enum MeetingsTypes {
  GET_ALL = '[meetings] GET_ALL',
  GET = '[meetings] GET',
  UPDATE = '[meetings] UPDATE',
  CREATE = '[meetings] CREATE',
  CREATE_DEFAULT = '[meetings] CREATE_DEFAULT',
  DELETE = '[meetings] DELETE',
  GET_BY_HOST = '[meetings] GET_BY_HOST',
  RESPOND_INVITATION = '[meetings] RESPOND_INVITATION',
  PUT_GAMES = '[meetings] PUT_GAMES',

  SET_IMAGE = '[meetings] SET_IMAGE',

  SET = '[meetings] SET',
  SET_ONE = '[meetings] SET_ONE',
  SET_QUERY_PARAMS = '[meetings] SET_QUERY_PARAMS',
  SET_SENT_RSVP = '[meetings] SET_SENT_RSVP',
  SET_HERO_IMAGE = '[meetings] SET_HERO_IMAGE',
  SET_LOGO_IMAGE = '[meetings] SET_LOGO_IMAGE',
  SET_SELECTED_MEETING = '[meetings] SET_SELECTED_MEETING',
  SET_LOADING = '[meetings] SET_LOADING',
  DELETE_COMPLETE = '[meetings] DELETE_COMPLETE',
  PUT_GAME_COMPLETE = '[meetings] PUT_GAME_COMPLETE'
}