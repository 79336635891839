import React from 'react';
import moment from 'moment';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core';
const MASK = 'MM/DD/yy hh:mm';
const styles = (theme: Theme) =>
    createStyles({
        root: {}
    });
interface Props extends WithStyles<typeof styles> {
    date: string;
    format?: string;
}
const DateCell: React.FC<Props> = ({ classes, date, format = MASK }) => (
    <div className={classes.root}>{moment(date).format(format)}</div>
);
export default withStyles(styles)(DateCell);
