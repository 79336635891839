import { EventImageType } from 'types/Enums';
import { Meetings, MeetingsActions } from 'types/Meetings';

export const getAllMeetings = (index: number): Meetings.TActions.GetAll => ({
  type: MeetingsActions.GET_ALL,
  payload: index
});

export const getMeeting = (meetingId: string | null, pub: boolean): Meetings.TActions.Get => ({
  type: MeetingsActions.GET,
  payload: {
    meetingId: meetingId,
    public: pub
  }
});

export const updateMeeting = (id: string, data: Meetings.TMeeting): Meetings.TActions.Update => ({
  type: MeetingsActions.UPDATE,
  payload: {
    meetingId: id,
    meeting: data
  }
});

export const createMeeting = (data: Meetings.TMeeting): Meetings.TActions.Create => ({
  type: MeetingsActions.CREATE,
  payload: data
});

export const createDefaultMeeting = (): Meetings.TActions.CreateDefault => ({
  type: MeetingsActions.CREATE_DEFAULT
});

export const deleteMeeting = (id: string): Meetings.TActions.Delete => ({
  type: MeetingsActions.DELETE,
  payload: id
});

export const getMeetingByHost = (host: string): Meetings.TActions.GetByHost => ({
  type: MeetingsActions.GET_BY_HOST,
  payload: host
});

export const respondInvitation = (id: string, response: Meetings.TInvitationResponse): Meetings.TActions.RespondInvitation => ({
  type: MeetingsActions.RESPOND_INVITATION,
  payload: {
    meetingId: id,
    response: response
  }
});

export const putGames = (id: string, games: string[]): Meetings.TActions.PutGames => ({
  type: MeetingsActions.PUT_GAMES,
  payload: {
    id,
    games
  }
});

export const setImage = (type: EventImageType, data: string | Blob): Meetings.TActions.SetImage => ({
  type: MeetingsActions.SET_IMAGE,
  payload: {
    type,
    data
  }
});

export const setMeetings = (data: Meetings.TMeetingsGetAllResponse): Meetings.TActions.Set => ({
  type: MeetingsActions.SET,
  payload: data
});

export const setMeeting = (meeting: Meetings.TMeeting | null): Meetings.TActions.SetOne => ({
  type: MeetingsActions.SET_ONE,
  payload: meeting
});

export const setMeetingQueryParams = (data: Partial<Meetings.TQueryParams>): Meetings.TActions.SetQueryParams => ({
  type: MeetingsActions.SET_QUERY_PARAMS,
  payload: data
});

export const setRSVPSent = (id: string, sent: boolean): Meetings.TActions.SetRSVPSent => ({
  type: MeetingsActions.SET_SENT_RSVP,
  payload: {
    id, sent
  }
});

export const setHeroImage = (url: string): Meetings.TActions.SetHeroImage => ({
  type: MeetingsActions.SET_HERO_IMAGE,
  payload: url
});

export const setLogoImage = (url: string): Meetings.TActions.SetLogoImage => ({
  type: MeetingsActions.SET_LOGO_IMAGE,
  payload: url
});

export const setSelectedMeeting = (page: number, index: number): Meetings.TActions.SetSelectedMeeting => ({
  type: MeetingsActions.SET_SELECTED_MEETING,
  payload: {
    page, index
  }
});

export const setLoading = (loading: boolean): Meetings.TActions.SetLoading => ({
  type: MeetingsActions.SET_LOADING,
  payload: loading
});

export const deleteComplete = (id: string): Meetings.TActions.DeleteComplete => ({
  type: MeetingsActions.DELETE_COMPLETE,
  payload: id
});

export const putGameComplete = (meeting: Meetings.TMeeting): Meetings.TActions.PutGameComplete => ({
  type: MeetingsActions.PUT_GAME_COMPLETE,
  payload: meeting
});
