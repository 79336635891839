import { IAction, IActionPayload, ITableQueryParams } from './utils';
import { MeetingsTypes } from 'Components/Scheduler/redux/types';
import { EventImageType } from './Enums';

export const MeetingsActions = MeetingsTypes;

export declare namespace Meetings {

  export type TInvitedParticipants = {
    email: string;
    status: string;
    sentOnUtc: Date;
    meetingInvitationStatus: string;
    answeredOnUtc: Date;
  };

  export type TPresentParticipants = {
    userId: string;
    email: string;
    joinedOnUtc: Date;
  };

  export type TUploadImage = {
    id: string;
    name: string;
    fileType: number;
    contentType: string;
    size: number,
    url: string
  };

  export type TInvitationImage = {
    logo: string | null;
    hero: string | null;
  };

  export type TInvitationConfiguration = {
    subject: string;
    messageTitle: string;
    messageContent: string;
    backgroundColor: string;
    textColor: string;
    buttonColor: string;
    buttonTextColor: string;
    invitationImage: TInvitationImage | null;
  };

  export type TMeetingDriverInfo = {
    platformUserId: string;
    xurealUserId: string;
    meetingId: string;
    joinUrl: string;
    password: string;
  };

  export type TInvitationResponse = {
    id: string;
    email: string;
    action: string;
    invitationToken: string | null;
  };

  export type TAssignedGame = {
    id: string;
    name: string;
    description: string;
    category: {
      id: string;
      name: string;
    },
    avatarUrl: string;
    assignedOnUtc: Date;
  }

  export type TMeeting = {
    id: string | null;
    code: string | null;
    hostUserId: string | null;
    name: string;
    description: string | null;
    time: Date;
    timezone: string;
    durationInMinutes: number;
    platform: string;
    invitedParticipants: TInvitedParticipants[];
    presentParticipants: TPresentParticipants[];
    presentHost: TPresentParticipants | null;
    invitationConfiguration: TInvitationConfiguration;
    meetingDriverInfo: TMeetingDriverInfo | null;
    assignedGames: TAssignedGame[];
  };

  export type TState = {
    loading: boolean;
    elements: TMeeting[];
    totalElementsCount: number;
    totalPagesCount: number;
    meeting: TMeeting | null;
    rsvpSent: {
      id: string | null,
      sent: boolean
    };
    heroImage: string | null;
    logoImage: string | null;
    selectedMeeting: {
      page: number;
      index: number;
    }
  };

  export type TMeetingsGetAllResponse = {
    elements: TMeeting[];
    totalElementsCount: number;
    totalPagesCount: number;
  };

  export type TMeetingSortFields = keyof TMeeting;
  export type TQueryParams = ITableQueryParams<TMeetingSortFields>;

  export namespace TActions {
    export type GetAll = IActionPayload<MeetingsTypes.GET_ALL, number>;
    export type Get = IActionPayload<MeetingsTypes.GET, { meetingId: string | null, public: boolean }>;
    export type Update = IActionPayload<
      MeetingsTypes.UPDATE,
      {
        meetingId: string;
        meeting: TMeeting
      }
    >;
    export type Create = IActionPayload<MeetingsTypes.CREATE, TMeeting>;
    export type CreateDefault = IAction<MeetingsTypes.CREATE_DEFAULT>;
    export type Delete = IActionPayload<MeetingsTypes.DELETE, string>;
    export type GetByHost = IActionPayload<MeetingsTypes.GET_BY_HOST, string>;
    export type RespondInvitation = IActionPayload<
      MeetingsTypes.RESPOND_INVITATION,
      {
        meetingId: string,
        response: TInvitationResponse
      }
    >;
    export type PutGames = IActionPayload<MeetingsTypes.PUT_GAMES, {
      id: string,
      games: string[]
    }>;

    export type SetImage = IActionPayload<MeetingsTypes.SET_IMAGE, {
      type: EventImageType,
      data: string | Blob
    }>;
    
    
    export type Set = IActionPayload<MeetingsTypes.SET, TMeetingsGetAllResponse>;
    export type SetOne = IActionPayload<MeetingsTypes.SET_ONE, TMeeting | null>;
    export type SetQueryParams = IActionPayload<MeetingsTypes.SET_QUERY_PARAMS, Partial<TQueryParams>>;
    export type SetRSVPSent = IActionPayload<MeetingsTypes.SET_SENT_RSVP, { id: string, sent: boolean }>;
    export type SetHeroImage = IActionPayload<MeetingsTypes.SET_HERO_IMAGE, string>;
    export type SetLogoImage = IActionPayload<MeetingsTypes.SET_LOGO_IMAGE, string>;
    export type SetSelectedMeeting = IActionPayload<MeetingsTypes.SET_SELECTED_MEETING, { page: number, index: number }>;
    export type SetLoading = IActionPayload<MeetingsTypes.SET_LOADING, boolean>;
    export type DeleteComplete = IActionPayload<MeetingsTypes.DELETE_COMPLETE, string>;
    export type PutGameComplete = IActionPayload<MeetingsTypes.PUT_GAME_COMPLETE, TMeeting>;

    export type AllReducer = | Set | SetOne | SetQueryParams | SetRSVPSent | SetHeroImage | SetLogoImage | SetSelectedMeeting | SetLoading | DeleteComplete | PutGameComplete;
    export type APIActions = GetAll | Get | Update | Create | CreateDefault | Delete | GetByHost | RespondInvitation | PutGames | SetImage;
  }
}