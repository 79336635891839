import React, { useState } from 'react';
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
    Grid,
    InputLabel,
    OutlinedInput,
    Button,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'Components/Shared';
import { authLogin } from '../../redux/actions';
import { useForm, TValidations } from '../../hooks/useForm';
import { Auth } from '../../types/Auth';
import { IAppState } from '../../types';
const styles = (theme: Theme) =>
    createStyles({
        root: { flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
        form: {
            overflow: 'hidden',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 450,
            border: `1px solid ${theme.backgroundColors?.secondaryGrey}`,
            borderRadius: theme.spacing(3),
            backgroundColor: theme.backgroundColors?.secondary,
            padding: 40,
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.25)'
        },
        formTitle: {
            fontSize: 22,
            fontWeight: 500,
            marginBottom: theme.spacing(3)
        },
        row: {
            width: '100%',
            marginBottom: theme.spacing(2),
            position: 'relative',
            paddingBottom: theme.spacing(3)
        },
        inputLable: {
            marginBottom: theme.spacing(1)
        },
        inputError: {
            color: theme.palette.error.main,
            bottom: 0,
            left: 10,
            position: 'absolute'
        },
        btnSubmit: {
            width: 200,
            height: 45,
            fontSize: 15
        }
    });
const initialState = {
    email: '',
    password: ''
};
const validations: TValidations<Auth.TCredentials> = {
    email: {
        pattern: {
            value: /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/,
            message: 'Email is incorrect'
        }
    },
    password: {
        required: true
    }
};

interface Props extends WithStyles<typeof styles> {}

const LoginPage: React.FC<Props> = ({ classes }) => {
    const dispatch = useDispatch();
    const loading = useSelector((state: IAppState) => Boolean(state.ui.loaders['auth']));
    const [showPassword, setShowPassword] = useState(false);
    const { values, errors, handleInput, isFormValid } = useForm<Auth.TCredentials>(
        initialState,
        validations
    );
    const formValid = isFormValid();
    const onSubmit = () => {
        if (!formValid) return;
        dispatch(authLogin(values));
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const renderContent = () => {
        if (loading) return <Loader text="Authenticating" />;
        return (
            <Grid
                container
                item
                className={classes.form}
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Grid item className={classes.formTitle}>
                    Admin Login
                </Grid>
                <Grid item classes={{ item: classes.row }}>
                    <InputLabel htmlFor="login-email" className={classes.inputLable}>
                        Email Address
                    </InputLabel>
                    <OutlinedInput
                        id="login-email"
                        fullWidth
                        color="primary"
                        name="email"
                        value={values.email}
                        onChange={handleInput}
                    />
                    {errors.email && <div className={classes.inputError}>{errors.email}</div>}
                </Grid>
                <Grid item classes={{ item: classes.row }}>
                    <InputLabel htmlFor="login-password" className={classes.inputLable}>
                        Password
                    </InputLabel>
                    <OutlinedInput
                        id="login-password"
                        fullWidth
                        name="password"
                        value={values.password}
                        onChange={handleInput}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {errors.password && <div className={classes.inputError}>{errors.password}</div>}
                </Grid>
                <Grid item>
                    <Button
                        disabled={!formValid}
                        className={classes.btnSubmit}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                    >
                        Login
                    </Button>
                </Grid>
            </Grid>
        );
    };
    return (
        <Grid container className={classes.root}>
            {renderContent()}
        </Grid>
    );
};

export default withStyles(styles)(LoginPage);
