import HttpClient from "./HttpClient";
import AuthApi, {Credentials} from "./AuthApi";
import Token from "../Models/Token";

class UserAuthApi implements AuthApi {

    private baseRoute = "/user"

    constructor(private httpClient : HttpClient) {
        this.authenticate = this.authenticate.bind(this)
        this.refreshToken = this.refreshToken.bind(this)
    }

    authenticate(credentials: Credentials): Promise<Token> {
       return this.httpClient.post(`${this.baseRoute}/authenticate`, {...credentials})
    }

    refreshToken(): Promise<Token> {
        return this.httpClient.post(`${this.baseRoute}/refresh-token`)
    }
}

export default UserAuthApi