import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Snackbar as MuiSnackbar,
    SnackbarProps as MuiSnackbarProps,
    makeStyles,
    Theme
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';

import { hideSnackbar } from '../../../redux/actions';
import { UI } from '../../../types/UI';
import { IAppState } from '../../../types';

const useStyles = makeStyles<Theme, { variant: UI.TSnackbars }>((theme) => ({
    snackContainer: {
        backgroundColor: (props) => {
            return props.variant === 'success'
                ? theme.palette.success.light
                : props.variant === 'error'
                ? theme.palette.error.main
                : theme.backgroundColors?.black;
        },
        color: theme.backgroundColors && theme.backgroundColors.white,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1.5),
        borderRadius: 5
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        borderBottom: `1px solid ${theme.backgroundColors && theme.backgroundColors.white}`,
        paddingBottom: theme.spacing(1)
    },
    icon: {
        cursor: 'pointer'
    },
    content: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    }
}));

interface SnackbarProps extends MuiSnackbarProps {
    title?: string;
    content?: string;
    variant?: UI.TSnackbars;
    handleClose?: (e?: any, r?: any) => void;
}

const Snackbar: React.FC<SnackbarProps> = ({ title, autoHideDuration = 4000 }) => {
    const dispatch = useDispatch();
    const {
        data: { type: variant, message: content },
        opened
    } = useSelector((state: IAppState) => state.ui.snackbar);
    const classes = useStyles({ variant });
    const headerText = title ? title : variant === 'error' ? 'Error' : 'Success';

    const handleClose = () => {
        dispatch(hideSnackbar());
    };

    return (
        <MuiSnackbar
            open={opened}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={handleClose}
        >
            <div className={classes.snackContainer}>
                <div className={classes.header}>
                    <span>{headerText}</span>
                    <HighlightOff className={classes.icon} onClick={handleClose} />
                </div>
                <div className={classes.content}>{content}</div>
            </div>
        </MuiSnackbar>
    );
};

export default Snackbar;
