import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { useTheme, Select, MenuItem, Button, ListItem } from '@material-ui/core';
import { Edit, Delete, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import RowMenu from './components/RowMenu';
import Loader from './Loader';

import { RolesEnum, Users } from '../../../types/Users';
import { IAppState } from '../../../types';

import { TABLE_ICONS } from './config';
import { useSelector } from 'react-redux';

interface IManageUsersTableProps {
    data: Users.TUsersTableRecord[];
    totalCount: number;
    pageNumber?: number;
    pageSize: number;
    sortBy: Users.TQueryParams['sortBy'];
    sortDirection: Users.TQueryParams['sortDirection'];
    isLoading: boolean;
    handleEditUser: (user: Users.TUsersTableRecord) => void;
    handleRemoveUser: (id: string) => void;
    handleQueryParams: (params: Partial<Users.TQueryParams>) => void;
}

const ManageUsersTable: React.FC<IManageUsersTableProps> = ({
    data,
    totalCount,
    pageSize,
    sortBy,
    sortDirection,
    isLoading,
    handleEditUser,
    handleRemoveUser,
    handleQueryParams
}) => {
    const theme = useTheme();
    const cellStyle = {
        borderBottomColor: theme.border?.row
    };
    const [colFilterState, setColFilterState] = useState<{
        anchor: HTMLElement | null;
        open: boolean;
        field: string;
        term: string;
    }>({ anchor: null, open: false, field: '', term: '' });
    const userRole = useSelector((state: IAppState) => state.auth.user?.roles);

    const permissionMap: any = {
        [RolesEnum.SuperUser]: { key: 'SuperUser', display: 'Trivio and XC - Super Admin' },
        [RolesEnum.HuddleGameSuperAdmin]: {
            key: 'HuddleGameSuperAdmin',
            display: 'Trivio - Admin'
        },
        [RolesEnum.HuddleGameAdmin]: { key: 'HuddleGameAdmin', display: 'Trivio - User' },
        [RolesEnum.CustomerGameSuperAdmin]: {
            key: 'CustomerGameSuperAdmin',
            display: 'XC - Admin'
        },
        [RolesEnum.CustomerGameAdmin]: { key: 'CustomerGameAdmin', display: 'XC - CARS' },
        [RolesEnum.Visitor]: { key: 'Visitor', display: 'XC - Guest/Visitor' }
    };
    

    const columnsDef = [
        { title: 'Name', field: 'name', sortField: 'firstName' },
        { title: 'Email', field: 'email', sortField: 'email' },
        // {
        //     title: 'Employee Id',
        //     field: 'employeeId',
        //     sortField: 'employeeId'
        // },
        {
            title: 'Role',
            field: 'role',
            sortField: 'role'
        }
    ];

    const onOrderChange = (orderBy: number, orderDirection: 'desc' | 'asc') => {
        if (orderBy >= 0) {
            const sortFiled = columnsDef[orderBy].sortField as keyof Users.IUser;
            handleQueryParams({ sortDirection: orderDirection, sortBy: sortFiled });
        } else {
            handleQueryParams({ sortBy: '', sortDirection: '' });
        }
    };

    const closeColFilter = () => {
        setColFilterState({
            anchor: null,
            open: false,
            field: '',
            term: ''
        });
    };

    const renderColFilter = () => {
        const { open, anchor, term } = colFilterState;
        return (
            <RowMenu anchorEl={anchor} open={open} onClose={closeColFilter}>
                <ListItem>
                    {open && (
                        <Select
                            labelId="create-update-user-role"
                            fullWidth
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }
                            }}
                            variant="outlined"
                            value={term}
                            inputProps={{
                                name: 'roles',
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                ) => {
                                    setColFilterState((prevState) => ({
                                        ...prevState,
                                        term: e.target.value
                                    }));
                                }
                            }}
                            displayEmpty
                            renderValue={(value) => {
                                if (value && typeof value === 'string') return value;
                                return <div className="MuiFormLabel-root">Select Account Role</div>;
                            }}
                            IconComponent={ExpandMoreIcon}
                        >
                            {Object.keys(RolesEnum).map((key) => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        {key}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                </ListItem>
                <ListItem>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleQueryParams({
                                filterBy: {
                                    field: colFilterState.field as Users.TSortFileds,
                                    term: colFilterState.term
                                }
                            });
                            closeColFilter();
                        }}
                    >
                        Search
                    </Button>
                </ListItem>
            </RowMenu>
        );
    };
    return (
        <>
            <MaterialTable
                style={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }}
                icons={TABLE_ICONS}
                isLoading={isLoading}
                columns={columnsDef.map((cDef) => {
                    if (cDef.sortField === sortBy && sortDirection) {
                        return { ...cDef, defaultSort: sortDirection };
                    }
                    return cDef;
                })}
                data={data.map((el) => ({
                    ...el,
                    role:
                        typeof el.role === 'string'
                            ? el.role
                                  .toString()
                                  .replace(/\s+/g, '')
                                  .split(',')
                                  .map((el: any) => permissionMap[el].display)
                                  .join(', ')
                            : el.role
                }))}
                totalCount={totalCount}
                onOrderChange={onOrderChange}
                options={{
                    headerStyle: {
                        backgroundColor: 'transparent',
                        borderBottomWidth: 2,
                        paddingBottom: 0,
                        paddingTop: 0,
                        ...cellStyle
                    },
                    actionsCellStyle: {
                        ...cellStyle
                    },
                    filtering: false,
                    toolbar: false,
                    paging: false,
                    selection: userRole === RolesEnum.SuperUser,
                    draggable: false,
                    columnsButton: false,
                    actionsColumnIndex: -1,
                    pageSize: pageSize
                }}
                components={{
                    OverlayLoading: Loader,
                    Pagination: () => null
                }}
                localization={{
                    header: {
                        actions: 'Actions'
                    },
                    body: {
                        emptyDataSourceMessage: 'No users.'
                    }
                }}
                actions={
                    userRole === RolesEnum.SuperUser
                        ? [
                              {
                                  position: 'row',
                                  icon: () => <Edit color="primary" />,
                                  tooltip: 'Edit User',
                                  onClick: (event, rowData) => {
                                      if (!Array.isArray(rowData)) handleEditUser(rowData);
                                  }
                              },
                              {
                                  position: 'row',
                                  icon: () => <Delete color="error" />,
                                  tooltip: 'Delete User',
                                  onClick: (event, rowData) => {
                                      if (!Array.isArray(rowData))
                                          handleRemoveUser(rowData.allFields.id);
                                  }
                              }
                          ]
                        : []
                }
            />
            {renderColFilter()}
        </>
    );
};

export default ManageUsersTable;
