import { Quizzes, QuizzesActions } from '../../../types/Quizzes';

export const getQuizzes = (
    data: Quizzes.TQueryParams,
    huddle: boolean
): Quizzes.TActions.GetAll => ({
    type: QuizzesActions.GET,
    payload: {
        params: data,
        huddle
    }
});
export const getQuiz = (id: string, huddle: boolean): Quizzes.TActions.GetOne => ({
    type: QuizzesActions.GET_QUIZ,
    payload: {
        id,
        huddle
    }
});

export const getPreviewQuiz = (id: string): Quizzes.TActions.GetPreview => ({
    type: QuizzesActions.GET_QUIZ_PREVIEW,
    payload: {
        id
    }
});
export const clearPreviewQuiz = (): Quizzes.TActions.ClearPreview => ({
    type: QuizzesActions.CLEAR_QUIZ_PREVIEW
});

export const copyQuiz = (id: string, huddle: boolean): Quizzes.TActions.CopyQuiz => ({
    type: QuizzesActions.COPY_QUIZ,
    payload: {
        id,
        huddle
    }
});

export const createQuiz = (
    quiz: Quizzes.TQuizDetailsFormFields,
    huddle: boolean
): Quizzes.TActions.Create => ({
    type: QuizzesActions.CREATE,
    payload: {
        fields: quiz,
        huddle
    }
});

export const updateQuiz = (
    quiz: Quizzes.TQuizDetailsFormFields,
    huddle: boolean
): Quizzes.TActions.Update => ({
    type: QuizzesActions.UPDATE,
    payload: {
        fields: quiz,
        huddle
    }
});
export const updatePendingQuestions = (
    idx: number,
    data: Quizzes.TQuestionFormFields,
    command: Quizzes.TPendingQuizCommand,
    huddle: boolean
): Quizzes.TActions.UpdatePendingQuestions => ({
    type: QuizzesActions.UPDATE_PENDING,
    payload: data,
    meta: {
        idx,
        section: 'question',
        command,
        huddle
    }
});
export const updatePendingRewards = (
    idx: number,
    data: Quizzes.TRewardFormFields,
    command: Quizzes.TPendingQuizCommand,
    huddle: boolean
): Quizzes.TActions.UpdatePendingRewards => ({
    type: QuizzesActions.UPDATE_PENDING_REWARDS,
    payload: data,
    meta: {
        idx,
        section: 'reward',
        command,
        huddle
    }
});
export const deleteQuiz = (huddle: boolean): Quizzes.TActions.Delete => ({
    type: QuizzesActions.DELETE,
    payload: {
        huddle
    }
});
export const enableQuiz = (id: string, huddle: boolean): Quizzes.TActions.Enable => ({
    type: QuizzesActions.ENABLE,
    payload: {
        id,
        huddle
    }
});
export const disableQuiz = (id: string, huddle: boolean): Quizzes.TActions.Disable => ({
    type: QuizzesActions.DISABLE,
    payload: {
        id,
        huddle
    }
});
export const getAllCategories = (huddle: boolean): Quizzes.TActions.GetAllCategories => ({
    type: QuizzesActions.GET_ALL_CATEGORIES,
    payload: {
        huddle
    }
});
export const getCategories = (
    data: Quizzes.TCatQueryParams,
    huddle: boolean
): Quizzes.TActions.GetCategories => ({
    type: QuizzesActions.GET_CATEGORIES,
    payload: {
        params: data,
        huddle
    }
});
export const createQuizCat = (
    category: Quizzes.TCategory,
    huddle: boolean
): Quizzes.TActions.CreateCategory => ({
    type: QuizzesActions.CREATE_CAT,
    payload: {
        category,
        huddle
    }
});
export const updateQuizCat = (
    category: Partial<Quizzes.TCategory>,
    huddle: boolean
): Quizzes.TActions.UpdateCategory => ({
    type: QuizzesActions.UPDATE_CAT,
    payload: {
        category,
        huddle
    }
});
export const deleteQuizCat = (huddle: boolean): Quizzes.TActions.DeleteCategory => ({
    type: QuizzesActions.DELETE_CAT,
    payload: {
        huddle
    }
});
export const getQuestions = (quizId: string, huddle: boolean): Quizzes.TActions.GetQuestions => ({
    type: QuizzesActions.GET_QUESTIONS,
    payload: {
        id: quizId,
        huddle
    }
});
export const createQuestion = (
    quizId: string,
    question: Quizzes.TQuestionFormFields,
    huddle: boolean
): Quizzes.TActions.CreateQuestion => ({
    type: QuizzesActions.CREATE_QUESTION,
    payload: {
        quizId,
        question,
        huddle
    }
});
export const createReward = (
    rewardId: string,
    reward: Quizzes.TRewardFormFields,
    huddle: boolean
): Quizzes.TActions.CreateReward => ({
    type: QuizzesActions.CREATE_REWARD,
    payload: {
        rewardId,
        reward,
        huddle
    }
});
export const updateQuestion = (
    quizId: string,
    question: Quizzes.TQuestionFormFields,
    huddle: boolean
): Quizzes.TActions.UpdateQuestion => ({
    type: QuizzesActions.UPDATE_QUESTION,
    payload: { quizId, question, huddle }
});
export const reorderQuestions = (
    quizId: string,
    questions: Quizzes.TQuestionFormFields[],
    huddle: boolean,
    deleteQuestions: string[]
): Quizzes.TActions.ReorderQuestions => ({
    type: QuizzesActions.REORDER_QUESTIONS,
    payload: { quizId, questions, huddle, deleteQuestions }
});
export const updateReward = (
    rewardId: string,
    reward: Quizzes.TRewardFormFields,
    huddle: boolean
): Quizzes.TActions.UpdateReward => ({
    type: QuizzesActions.UPDATE_REWARD,
    payload: { rewardId, reward, huddle }
});
export const deleteQuestion = (
    quizId: string,
    questionId: string,
    huddle: boolean
): Quizzes.TActions.DeleteQuestion => ({
    type: QuizzesActions.DELETE_QUESTION,
    payload: { quizId, questionId, huddle }
});
export const deleteReward = (
    quizId: string,
    rewardId: string,
    huddle: boolean
): Quizzes.TActions.DeleteReward => ({
    type: QuizzesActions.DELETE_REWARD,
    payload: { quizId, rewardId, huddle }
});
export const setQuizzes = (
    data: Quizzes.TQuizGetAllResponse,
    huddle: boolean
): Quizzes.TActions.Set => ({
    type: QuizzesActions.SET,
    payload: {
        response: data,
        huddle
    }
});
export const setQuiz = (
    quiz: Quizzes.TDefinition | null,
    huddle: boolean
): Quizzes.TActions.SetOne => ({
    type: QuizzesActions.SET_ONE,
    payload: {
        definition: quiz,
        huddle
    }
});
export const setPreviewQuiz = (
    quiz: Quizzes.TDefinition | null,
    huddle: boolean
): Quizzes.TActions.SetPreview => ({
    type: QuizzesActions.SET_PREVIEW,
    payload: {
        definition: quiz,
        huddle
    }
});
export const setQuestions = (
    questions: Quizzes.TDefinition['questions'],
    huddle: boolean
): Quizzes.TActions.SetQuestions => ({
    type: QuizzesActions.SET_QUESTIONS,
    payload: {
        questions,
        huddle
    }
});
export const setPendingQuestions = (
    questions: Quizzes.TDefinition['questions'],
    huddle: boolean
): Quizzes.TActions.SetPendingQuestions => ({
    type: QuizzesActions.SET_PENDING,
    payload: {
        questions,
        huddle
    }
});
export const setPendingRewards = (
    rewards: Quizzes.TDefinition['rewards'],
    huddle: boolean
): Quizzes.TActions.SetPendingRewards => ({
    type: QuizzesActions.SET_PENDING_REWARDS,
    payload: {
        rewards,
        huddle
    }
});
export const setQueryParamsQuizzes = (
    data: Partial<Quizzes.TQueryParams>,
    huddle: boolean
): Quizzes.TActions.SetQueryParams => ({
    type: QuizzesActions.SET_QUERY_PARAMS,
    payload: {
        params: data,
        huddle
    }
});
export const setCategories = (
    data: Quizzes.TCategoryGetAllResponse,
    huddle: boolean
): Quizzes.TActions.SetCategories => ({
    type: QuizzesActions.SET_CATEGORIES,
    payload: {
        response: data,
        huddle
    }
});
export const setCatQueryParamsQuizzes = (
    data: Partial<Quizzes.TCatQueryParams>,
    huddle: boolean
): Quizzes.TActions.SetCatQueryParams => ({
    type: QuizzesActions.SET_CAT_QUERY_PARAMS,
    payload: {
        params: data,
        huddle
    }
});
export const setAvatar = (data: string | Blob): Quizzes.TActions.SetAvatar => ({
    type: QuizzesActions.SET_AVATAR,
    payload: data
});
export const setTempImage = (url: string | null): Quizzes.TActions.SetTempImage => ({
    type: QuizzesActions.SET_TEMP_IMAGE,
    payload: {
        data: url
    }
});

export const setQuestionImage = (
    file: string | Blob,
    id: string
): Quizzes.TActions.SetQuizQestionImage => {
    return {
        type: QuizzesActions.SET_QUESTION_IMAGE,
        payload: {
            file,
            id
        }
    };
};
export const setQuestionImageURL = (
    id: string,
    url: string | null,
    size: string,
    dimensions: string
): Quizzes.TActions.SetQuizQuestionImageURL => {
    console.log('image recieved', url);
    return {
        type: QuizzesActions.SET_QUESTION_IMAGE_URL,
        payload: {
            id,
            url,
            size,
            dimensions
        }
    };
};

export const clearQuestionURLs = (): Quizzes.TActions.ClearQuestionURLs => ({
    type: QuizzesActions.CLEAR_QUESTION_URLS
});

export const getGameCode = (id: string, huddle: boolean): Quizzes.TActions.GetGameCode => ({
    type: QuizzesActions.GET_GAMECODE,
    payload: { id, huddle }
});
export const setGameCode = (id: string | null): Quizzes.TActions.SetGameCode => ({
    type: QuizzesActions.SET_GAMECODE,
    payload: id
});

export const setQuizDetails = (name: string, description: string, categoryId: string, isPublic: boolean): Quizzes.TActions.setVisibility => ({
    type: QuizzesActions.SET_VISIBILITY,
    payload: {
        name,
        description,
        categoryId,
        isPublic
    }
});

export const clearQuiz = (): Quizzes.TActions.ClearQuiz => ({
    type: QuizzesActions.CLEAR_QUIZ
});
