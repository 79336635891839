import { ITableQueryParams } from '../../types/utils';

export const handleQueryParamsData: <Fields>(data: ITableQueryParams<Fields>) => string = (
    data
) => {
    const { pageNumber, pageSize, sortBy, sortDirection, searchTerm, filterBy } = data;
    let params = '';

    if (typeof pageNumber === 'number' && typeof pageSize === 'number')
        params += `pageNumber=${pageNumber + 1}&pageSize=${pageSize}`;
    if (sortBy) params += `&sortBy=${sortDirection === 'desc' ? '-' : ''}${sortBy}`;
    if (searchTerm) params += `&searchTerm=${searchTerm}`;
    if (filterBy) params += `&${filterBy.field}=${filterBy.term}`;

    return params;
};

export const handleGenerateError = (error: any) => {
    const { response } = error;

    if (response) {
        console.error('axios error: ', response);
        const uiError = {
            code: 1,
            message: 'Api Error'
        };
        uiError.code = response.status;
        uiError.message = response.statusText;
        return uiError;
    } else {
        console.error('Api error: ', error);
        return error;
    }
};
