import { ModalsEnum } from '../../types/Modal';
import { Users, UsersActions } from '../../types/Users';

/**
 * Users Redux Action
 * @param data Users.TQueryParams
 */
export const getUsers = (data: Users.TQueryParams): Users.TActions.GetAll => ({
    type: UsersActions.GET,
    payload: data
});

/**
 * Users Redux Action
 * @param id string
 */
export const getUser = (id: string): Users.TActions.GetUser => ({
    type: UsersActions.GET_UESR,
    payload: id
});
/**
 * Users Redux Action
 * @param data Users.IUser
 */
export const createUser = (data: Users.IUser): Users.TActions.Create => ({
    type: UsersActions.CREATE,
    payload: data,
    meta: {
        modal: ModalsEnum.CREATE_USER
    }
});

/**
 * Users Redux Action
 * @param data Users.IUser
 */
export const updateUser = (data: Users.IUser): Users.TActions.Update => ({
    type: UsersActions.UPDATE,
    payload: data,
    meta: {
        modal: ModalsEnum.UPDATE_USER
    }
});

/**
 * Users Redux Action
 */
export const deleteUser = (): Users.TActions.Delete => ({
    type: UsersActions.DELETE,
    payload: null,
    meta: {
        modal: ModalsEnum.CONFIRM
    }
});

/**
 * Users Redux Action
 * @param data Users.IUserFetchResponse
 */
export const setUsers = (data: Users.IUserFetchResponse): Users.TActions.Set => ({
    type: UsersActions.SET,
    payload: data
});

/**
 * Users Redux Action
 * @param data Partial<Users.TQueryParams>
 */
export const setQueryParamsUsers = (
    data: Partial<Users.TQueryParams>
): Users.TActions.SetQueryParams => ({
    type: UsersActions.SET_QUERY_PARAMS,
    payload: data
});
