import { ShowType } from 'types/Enums';
import { Auth, AuthActions } from '../../types/Auth';

const initialState: Auth.TState = {
    user: null,
    accessTokenExpiresOn: '',
    community: ShowType.ALL,
    normalPermission: {
        canRead: false,
        canEdit: false,
        canCreate: false,
        canDelete: false,
        canDeleteOwn: false,
        canEditOwn: false
    },
    huddlePermission: {
        canRead: false,
        canEdit: false,
        canCreate: false,
        canDelete: false,
        canDeleteOwn: false,
        canEditOwn: false
    }
};

const authReducer = (state = initialState, action: Auth.TActions.AllReducer): Auth.TState => {
    switch (action.type) {
        case AuthActions.SUCCESS:

            const community = action.payload.community !== ShowType.SAME ? action.payload.community : initialState.community

            return {
                user: action.payload.user,
                accessTokenExpiresOn: action.payload.accessTokenExpiresOn,
                community,
                normalPermission: action.payload.normalPermission,
                huddlePermission: action.payload.huddlePermission
            };
        case AuthActions.CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default authReducer;
