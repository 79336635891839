import { ModalsEnum, Modal, ModalActions } from '../../types/Modal';

export const openModal = (modalData: Modal.TState): Modal.TActions.Open => ({
    type: ModalActions.OPEN,
    payload: modalData
});

export const closeModal = (modalKey: ModalsEnum | null): Modal.TActions.Close => ({
    type: ModalActions.CLOSE,
    payload: modalKey
});
