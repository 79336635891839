const COMBINED_URL = 'https://api.dev.xureal.com:5000/api';
// const COMBINED_URL = 'https://api.staging.xureal.com:5000/api';
export default {
    COMPANY_SERVICE_URL: COMBINED_URL || 'http://api.dev.xureal.com:5003/api',
    USER_SERVICE_URL: COMBINED_URL || 'http://api.dev.xureal.com:5001/api',
    QUIZ_SERVICE_URL: COMBINED_URL || 'http://api.dev.xureal.com:5007/api',
    API_TIMEOUT: 6000
};

export const routes = {
    login: '/login',
    changePassword: '/change-password',
    users: '/users',
    quizAll: '/quiz/all',
    quizManage: '/quiz/manage',
    quizCategories: '/quiz/categories',
    huddleAll: '/trivio/quizzes',
    huddleManage: '/trivio/manage',
    huddleCategories: '/trivio/categories',
    scheduler: '/scheduler',
    calendar: '/scheduler/calendar',
    createMeeting: '/scheduler/new-meeting',
    createEvent: '/scheduler/new-event',
    editEvent: '/scheduler/edit-event',
    previewInvitation: '/scheduler/preview-invitation'
};
