import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, Select, MenuItem } from '@material-ui/core';
import { MTablePagination } from 'material-table';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { TABLE_ICONS } from '../config';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        border: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        height: 36
    },
    selectContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    select: {
        height: 36,
        marginLeft: theme.spacing(1.5),
        backgroundColor: theme.backgroundColors?.white,
        '& .MuiSelect-icon': {
            color: theme.palette.primary.main
        }
    }
}));

interface Props {
    countText: React.ReactNode;
    count: number;
    page: number;
    labelRowsPerPage: React.ReactNode;
    rowsPerPage: number;
    forCategory?: boolean;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number
    ) => void;
}

const Pagination: React.FC<Props> = (props) => {
    const classes = useStyles();
    const {
        count,
        countText,
        page = 0,
        labelRowsPerPage,
        forCategory,
        rowsPerPage = forCategory ? 12 : 10,
        onChangeRowsPerPage,
        onChangePage
    } = props;

    const [sizeOptions, setSizeOptions] = useState<string[]>([]);

    useEffect(() => {
        const options = [];
        if (forCategory) {
            options.push('12');
            if (count > 24) {
                options.push('24');
            }
            if (count > 48) {
                options.push('48');
            }
        } else {
            options.push('10');
            if (count > 20) {
                options.push('20');
            }
            if (count > 50) {
                options.push('50');
            }
        }
        
        options.push('All');
        setSizeOptions(options);
    }, [count, forCategory]);

    const renderSelect = () => {
        return (
            <Select
                value={rowsPerPage}
                variant="outlined"
                className={classes.select}
                inputProps={{
                    onChange: onChangeRowsPerPage
                }}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                }}
                IconComponent={ExpandMoreIcon}
            >
                {sizeOptions.map((item) => {
                    const value = item !== 'All' ? parseInt(item) : count;
                    return (
                        <MenuItem key={value} value={value}>
                            {item}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    };

    return (
        <div className={classes.root}>
            {count} {countText}
            <div className={classes.selectContainer}>
                {labelRowsPerPage} {renderSelect()}
            </div>
            <MTablePagination
                icons={TABLE_ICONS}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={onChangePage}
                showFirstLastPageButtons
            />
        </div>
    );
};

export default Pagination;
