import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
    createStyles({
        header: {
            height: 90,
            minWidth: 890,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            backgroundColor: theme.backgroundColors?.white,
            boxShadow: '2px 0px 9px 0px rgba(0,0,0,0.2)',
            zIndex: 1,
            overflow: 'hidden'
        }
    });
interface Props extends WithStyles<typeof styles> {}

const TopBar: React.FC<Props> = ({ children, classes }) => {
    return (
        <Grid item container component="header" className={classes.header}>
            {children}
        </Grid>
    );
};

export default withStyles(styles)(TopBar);
