import React from 'react';
import { withStyles, createStyles, Theme, WithStyles, Chip } from '@material-ui/core';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.backgroundColors?.secondaryBlue,
            color: theme.text?.primary,
            borderRadius: 5,
            textTransform: 'uppercase',
            lineHeight: 1
        }
    });
interface Props extends WithStyles<typeof styles> {
    label: any;
}
const ChipCell: React.FC<Props> = ({ classes, label }) => {
    return <Chip size="small" className={classes.root} label={label} />;
};

export default withStyles(styles)(ChipCell);
