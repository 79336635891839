import { ModalsEnum, ModalTypesEmun } from './Enums';
import { IActionPayload } from './utils';

export { ModalsEnum } from './Enums';

/** -> Modal Action Types */
export const ModalActions = ModalTypesEmun;

export declare namespace Modal {
    export type TState = {
        opened: ModalsEnum | null;
        data?: any /** TODO => Fix Types */;
    };

    export namespace TActions {
        export type Open = IActionPayload<ModalTypesEmun.OPEN, TState>;
        export type Close = IActionPayload<ModalTypesEmun.CLOSE, ModalsEnum | null>;

        export type AllReducer = Open | Close;
    }
}
