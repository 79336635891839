import { TypeOfProp } from '../../types/utils';
import { UI, LoadersActions, SnackbarActions, NavigationActions } from '../../types/UI';

type TSnackbarData = TypeOfProp<TypeOfProp<UI.TState, 'snackbar'>, 'data'>;

const initialState: UI.TState = {
    snackbar: {
        opened: false,
        data: {} as TSnackbarData
    },
    loaders: {} as TypeOfProp<UI.TState, 'loaders'>,
    navigation: {
        to: ''
    }
};

const uiReducer = (state = initialState, action: UI.TActions.AllReducer): UI.TState => {
    switch (action.type) {
        case LoadersActions.SHOW:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [action.payload]: true
                }
            };
        case LoadersActions.HIDE:
            const { [action.payload]: _, ...rest } = state.loaders;
            return { ...state, loaders: rest as TypeOfProp<UI.TState, 'loaders'> };

        case SnackbarActions.SHOW:
            return { ...state, snackbar: action.payload };
        case SnackbarActions.HIDE:
            return {
                ...state,
                snackbar: {
                    opened: false,
                    data: state.snackbar.data
                }
            };

        case NavigationActions.GO_TO:
            return {
                ...state,
                navigation: {
                    to: action.payload
                }
            };
        case NavigationActions.CLEAR:
            return {
                ...state,
                navigation: {
                    to: ''
                }
            };
        default:
            return state;
    }
};

export default uiReducer;
