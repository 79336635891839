import {
    QuestionsEnum,
    QuestionFormatEnum,
    GameDefinitionState,
    AnswerFormatEnum,
    PrizeTypeEnum
} from './Enums';
import { IAction, IActionMeta, IActionPayload, ITableQueryParams } from './utils';
import { QuizzesTypes } from '../Components/Quiz/redux/types';

/** -> Quizzes Action Types */
export const QuizzesActions = QuizzesTypes;
export const QTypes = QuestionsEnum;
export const QFormats = QuestionFormatEnum;
export const QuizStates = GameDefinitionState;
export const AFormats = AnswerFormatEnum;
export const PTypes = PrizeTypeEnum;

export declare namespace Quizzes {
    /** -> API Error */
    export type TAPIError = {
        ErrorCode: number;
        ErrorDescription: string;
        ErrorDetails: {
            [key: string]: string;
        };
    };

    export type TError = {
        code: number;
        message: string;
    };

    export type TTier = {
        name: string;
        points: number;
    };

    type QuestionImage = {
        url: string;
        size: string;
        dimensions: string
    }

    /** Question Redux Store Type */
    export type TQuestion = {
        id: string;
        quizQuestionType: QuestionsEnum | 'SingleChoice';
        quizQuestionFormat: QuestionFormatEnum | 'Text';
        questionText: string;
        answerChoices: string[];
        correctAnswerChoices: number[];
        explanation: string;
        points: number;
        images?: string[];
        updatedOnUtc?: string;
        maxTime: string;
    };



    /** Question UI State Type */
    export type TQuestionFormFields = {
        id?: string;
        questionType: QuestionsEnum | 'SingleChoice';
        questionFormat: QuestionFormatEnum | 'Text';
        questionText: string;
        imageUpload?: string;
        explanation: string;
        answerFormat: AnswerFormatEnum | '';
        answerChoices: string[];
        answerPoints: number[];
        correctAnswers: number[];
        questionImages?: QuestionImage[];
        updatedAt?: string;
        time: number;
    };

    /** Reward Redux Store Type */
    export type TReward = {
        id?: string;
        prizeType: PrizeTypeEnum | 'Tour Miles';
        coin?: string;
        amount: string;
    };

    /** Reward UI State Type */
    export type TRewardFormFields = {
        id?: string;
        prizeType: PrizeTypeEnum | 'Tour Miles';
        coin?: string;
        amount: string;
    };

    /** Prize */
    export type Image = {
        url: string;
        name?: string;
        size: number;
        dimensions: string;
        date: string;
    };

    export type TPrizeFormFields = {
        id?: string;
        name: string | '';
        prizeType: PrizeTypeEnum | '';
        image?: Image | '';
    };

    export type TCreatedBy = {
        name: string;
        id: string;
    };

    export type TDefinition = {
        description: string;
        questions: TQuestion[];
        rewards: TReward[];
        passFailPercent: number;
        id: string;
        name: string;
        category: TCategory;
        tiers: TTier[];
        createdOnUtc: string;
        gameDefinitionState: GameDefinitionState;
        avatarUrl: string | null;
        isPublic: boolean;
        createdBy: TCreatedBy;
    };

    /** Quiz Details UI Store Type */
    export type TQuizDetatils = Pick<
        TDefinition,
        'id' | 'name' | 'category' | 'description' | 'avatarUrl' | 'isPublic'
    >;
    /** Quiz Details UI State Type */
    export type TQuizDetailsFormFields = {
        id: string;
        name: string;
        categoryId: string;
        description: string;
        avatarUrl: string | null;
        isPublic: boolean;
    };

    export type TCategory = {
        id: string;
        name?: string;
        gamesCount?: number;
    };

    export type TQuizTableRecord = {
        id: string;
        name: string;
        category: {
            id: string;
            name: string;
        };
        createOn: string;
        status: GameDefinitionState;
        visibility: string;
        createdBy: {
            id: string;
            name: string;
        };
        allFields: TDefinition;
        tableData: {
            id: number;
            checked: boolean;
        };
    };

    /** -> Payload from Quizzes Fetch All Success */
    export type TQuizGetAllResponse = {
        elements: TDefinition[];
        totalElementsCount: number;
        totalPagesCount: number;
    };

    /** -> Payload from Quizzes Fetch All Success */
    export type TQuizGetResponse = {
        elements: TDefinition[];
        totalElementsCount: number;
        totalPagesCount: number;
    };

    /** -> Payload from Quiz Categories Fetch All Success */
    export type TCategoryGetAllResponse = {
        elements: TCategory[];
        totalElementsCount: number;
        totalPagesCount: number;
    };

    export type TQuizQuestionImages = {
        [key: string]: {
            url: string | null,
            size: string,
            dimensions: string
        }
    };

    export type TState = {
        data: TDefinition[];
        totalElementsCount: number;
        totalPagesCount: number;
        quiz: TDefinition | null;
        quizImages: TQuizQuestionImages;
        preview?: TDefinition | null;
        pendingQuestions: TQuestion[];
        pendingRewards: TReward[];
        categories: {
            data: TCategory[];
            totalElementsCount: number;
            totalPagesCount: number;
            tableQueryParams: TCatQueryParams;
        };
        tableQueryParams: TQueryParams;
        tempAvatar: string | null;
        triviaMeetingId: string | null;
    };

    export type TQuizSortFileds = keyof TQuizTableRecord;
    export type TQueryParams = ITableQueryParams<TQuizSortFileds>;
    export type TCatSortFileds = keyof TCategory;
    export type TCatQueryParams = ITableQueryParams<TCatSortFileds>;

    export type TPendingQuizPart = 'question';
    export type TPendingQuizPart1 = 'reward';
    export type TPendingQuizCommand = 'add' | 'update' | 'remove';
    export namespace TActions {
        export type GetAll = IActionPayload<
            QuizzesTypes.GET,
            { params: TQueryParams; huddle: boolean }
        >;
        export type GetOne = IActionPayload<QuizzesTypes.GET_QUIZ, { id: string; huddle: boolean }>;
        export type GetPreview = IActionPayload<QuizzesTypes.GET_QUIZ_PREVIEW, { id: string }>;
        export type ClearPreview = IAction<QuizzesTypes.CLEAR_QUIZ_PREVIEW>;

        export type Create = IActionPayload<
            QuizzesTypes.CREATE,
            { fields: TQuizDetailsFormFields; huddle: boolean }
        >;
        export type Update = IActionPayload<
            QuizzesTypes.UPDATE,
            { fields: TQuizDetailsFormFields; huddle: boolean }
        >;

        export type Enable = IActionPayload<QuizzesTypes.ENABLE, { id: string; huddle: boolean }>;
        export type Disable = IActionPayload<QuizzesTypes.DISABLE, { id: string; huddle: boolean }>;
        export type Delete = IActionPayload<QuizzesTypes.DELETE, { huddle: boolean }>;

        /**
         * CATEGORIES
         */
        export type GetAllCategories = IActionPayload<
            QuizzesTypes.GET_ALL_CATEGORIES,
            { huddle: boolean }
        >;
        export type GetCategories = IActionPayload<
            QuizzesTypes.GET_CATEGORIES,
            {
                params: TCatQueryParams;
                huddle: boolean;
            }
        >;
        export type CreateCategory = IActionPayload<
            QuizzesTypes.CREATE_CAT,
            { category: TCategory; huddle: boolean }
        >;
        export type UpdateCategory = IActionPayload<
            QuizzesTypes.UPDATE_CAT,
            {
                category: Partial<TCategory>;
                huddle: boolean;
            }
        >;
        export type DeleteCategory = IActionPayload<QuizzesTypes.DELETE_CAT, { huddle: boolean }>;
        /**
         * QUESTIONS
         */
        export type GetQuestions = IActionPayload<
            QuizzesTypes.GET_QUESTIONS,
            { id: string; huddle: boolean }
        >;
        export type CreateQuestion = IActionPayload<
            QuizzesTypes.CREATE_QUESTION,
            {
                quizId: string;
                question: TQuestionFormFields;
                huddle: boolean;
            }
        >;
        export type UpdateQuestion = IActionPayload<
            QuizzesTypes.UPDATE_QUESTION,
            {
                quizId: string;
                question: TQuestionFormFields;
                huddle: boolean;
            }
        >;
        export type ReorderQuestions = IActionPayload<
            QuizzesTypes.REORDER_QUESTIONS,
            {
                quizId: string;
                questions: TQuestionFormFields[];
                huddle: boolean;
                deleteQuestions: string[];
            }
        >;
        export type DeleteQuestion = IActionPayload<
            QuizzesTypes.DELETE_QUESTION,
            {
                quizId: string;
                questionId: string;
                huddle: boolean;
            }
        >;
        export type DeleteReward = IActionPayload<
            QuizzesTypes.DELETE_REWARD,
            {
                quizId: string;
                rewardId: string;
                huddle: boolean;
            }
        >;
        export type CreateReward = IActionPayload<
            QuizzesTypes.CREATE_REWARD,
            {
                rewardId: string;
                reward: TRewardFormFields;
                huddle: boolean;
            }
        >;
        export type UpdateReward = IActionPayload<
            QuizzesTypes.UPDATE_REWARD,
            {
                rewardId: string;
                reward: TRewardFormFields;
                huddle: boolean;
            }
        >;
        /**
         * PENDING QUIZ QUESTIONS
         * Used to update quiz sections on quiz creation
         */
        export type UpdatePendingQuestions = IActionMeta<
            QuizzesTypes.UPDATE_PENDING,
            TQuestionFormFields,
            {
                section: TPendingQuizPart;
                command: TPendingQuizCommand;
                idx: number;
                huddle: boolean;
            }
        >;

        export type UpdatePendingRewards = IActionMeta<
            QuizzesTypes.UPDATE_PENDING_REWARDS,
            TRewardFormFields,
            {
                section: TPendingQuizPart1;
                command: TPendingQuizCommand;
                idx: number;
                huddle: boolean;
            }
        >;

        export type Set = IActionPayload<
            QuizzesTypes.SET,
            { response: TQuizGetAllResponse; huddle: boolean }
        >;
        export type SetOne = IActionPayload<
            QuizzesTypes.SET_ONE,
            { definition: TDefinition | null; huddle: boolean }
        >;
        export type SetPreview = IActionPayload<
            QuizzesTypes.SET_PREVIEW,
            { definition: TDefinition | null; huddle: boolean }
        >;
        export type SetQuestions = IActionPayload<
            QuizzesTypes.SET_QUESTIONS,
            { questions: TQuestion[]; huddle: boolean }
        >;
        export type SetPendingQuestions = IActionPayload<
            QuizzesTypes.SET_PENDING,
            { questions: TQuestion[]; huddle: boolean }
        >;
        export type SetPendingRewards = IActionPayload<
            QuizzesTypes.SET_PENDING_REWARDS,
            { rewards: TReward[]; huddle: boolean }
        >;
        export type SetQueryParams = IActionPayload<
            QuizzesTypes.SET_QUERY_PARAMS,
            {
                params: Partial<TQueryParams>;
                huddle: boolean;
            }
        >;
        export type SetCategories = IActionPayload<
            QuizzesTypes.SET_CATEGORIES,
            {
                response: TCategoryGetAllResponse;
                huddle: boolean;
            }
        >;
        export type SetCatQueryParams = IActionPayload<
            QuizzesTypes.SET_CAT_QUERY_PARAMS,
            {
                params: Partial<TCatQueryParams>;
                huddle: boolean;
            }
        >;

        export type SetAvatar = IActionPayload<QuizzesTypes.SET_AVATAR, string | Blob>;
        export type SetTempImage = IActionPayload<
            QuizzesTypes.SET_TEMP_IMAGE,
            { data: string | null }
        >;

        export type SetQuizQestionImage = IActionPayload<
            QuizzesTypes.SET_QUESTION_IMAGE,
            {
                file: string | Blob;
                id: string;
            }
        >;
        export type SetQuizQuestionImageURL = IActionPayload<
            QuizzesTypes.SET_QUESTION_IMAGE_URL,
            { id: string; url: string | null, size: string, dimensions: string }
        >;

        export type GetGameCode = IActionPayload<
            QuizzesTypes.GET_GAMECODE,
            {
                id: string;
                huddle: boolean;
            }
        >;
        export type SetGameCode = IActionPayload<QuizzesTypes.SET_GAMECODE, string | null>;

        export type setVisibility = IActionPayload<
            QuizzesTypes.SET_VISIBILITY,
            { 
                name: string,
                description: string,
                isPublic: boolean,
                categoryId: string
            }
        >;

        export type CopyQuiz = IActionPayload<
            QuizzesTypes.COPY_QUIZ,
            { id: string; huddle: boolean }
        >;
        export type ClearQuiz = IAction<QuizzesTypes.CLEAR_QUIZ>;
        export type ClearQuestionURLs = IAction<QuizzesTypes.CLEAR_QUESTION_URLS>;

        export type AllReducer =
            | Set
            | SetOne
            | SetPreview
            | ClearPreview
            | SetQueryParams
            | SetCategories
            | SetCatQueryParams
            | SetQuestions
            | SetPendingQuestions
            | SetPendingRewards
            | SetTempImage
            | SetGameCode
            | setVisibility
            | SetQuizQuestionImageURL
            | ClearQuestionURLs
            | ClearQuiz;
        export type APIActions =
            | GetAll
            | GetOne
            | Create
            | Update
            | Enable
            | Disable
            | CopyQuiz
            | GetPreview
            | Delete
            | SetAvatar
            | SetQuizQestionImage
            | GetGameCode;
        export type APICatActions =
            | GetCategories
            | CreateCategory
            | UpdateCategory
            | DeleteCategory
            | GetAllCategories;
        export type APIQuestions =
            | GetQuestions
            | CreateQuestion
            | UpdateQuestion
            | DeleteQuestion
            | ReorderQuestions;
    }
}
