import { takeLatest, fork } from 'redux-saga/effects';
import { authAPISaga, checkLocalToken, handleSyncTokenTask } from './authSaga';
import { usersAPISaga } from './usersSagas';

import { AuthActions } from '../../types/Auth';
import { UsersActions } from '../../types/Users';
import { QuizzesActions } from '../../types/Quizzes';
import { MeetingsActions } from '../../types/Meetings';
import {
    pendingQuestionsSaga,
    pendingRewardsSaga,
    quizzCategoriesAPISaga,
    quizzesAPISaga,
    quizzQuestionsAPISaga
} from "../../Components/Quiz/redux/saga";

import { meetingsAPISaga } from 'Components/Scheduler/redux/saga';

export default function* rootSaga() {
    yield fork(handleSyncTokenTask);
    yield fork(checkLocalToken);

    yield takeLatest(
        [
            AuthActions.LOGIN,
            AuthActions.REFRESH_TOKEN,
            AuthActions.LOGOUT,
            AuthActions.CHANGE_PASSWORD,
            AuthActions.SSO_LOGIN
        ],
        authAPISaga
    );

    yield takeLatest(QuizzesActions.UPDATE_PENDING, pendingQuestionsSaga);
    yield takeLatest(QuizzesActions.UPDATE_PENDING_REWARDS, pendingRewardsSaga);
    yield takeLatest(
        [UsersActions.GET, UsersActions.CREATE, UsersActions.UPDATE, UsersActions.DELETE],
        usersAPISaga
    );
    yield takeLatest(
        [
            QuizzesActions.GET,
            QuizzesActions.GET_QUIZ,
            QuizzesActions.GET_QUIZ_PREVIEW,
            QuizzesActions.COPY_QUIZ,
            QuizzesActions.CREATE,
            QuizzesActions.UPDATE,
            QuizzesActions.ENABLE,
            QuizzesActions.DISABLE,
            QuizzesActions.DELETE,
            QuizzesActions.SET_AVATAR,
            QuizzesActions.SET_QUESTION_IMAGE,
            QuizzesActions.GET_GAMECODE,
            QuizzesActions.GET_PERMISSON
        ],
        quizzesAPISaga
    );
    yield takeLatest(
        [
            QuizzesActions.GET_QUESTIONS,
            QuizzesActions.CREATE_QUESTION,
            QuizzesActions.UPDATE_QUESTION,
            QuizzesActions.REORDER_QUESTIONS,
            QuizzesActions.DELETE_QUESTION
        ],
        quizzQuestionsAPISaga
    );
    yield takeLatest(
        [
            QuizzesActions.GET_ALL_CATEGORIES,
            QuizzesActions.GET_CATEGORIES,
            QuizzesActions.CREATE_CAT,
            QuizzesActions.UPDATE_CAT,
            QuizzesActions.DELETE_CAT
        ],
        quizzCategoriesAPISaga
    );
    yield takeLatest(
        [
            MeetingsActions.GET,
            MeetingsActions.GET_ALL,
            MeetingsActions.CREATE,
            MeetingsActions.RESPOND_INVITATION,
            MeetingsActions.UPDATE,
            MeetingsActions.SET_IMAGE,
            MeetingsActions.DELETE,
            MeetingsActions.PUT_GAMES
        ],
        meetingsAPISaga
    )
}
