import React from 'react';
import { makeStyles, Theme, Menu, MenuProps } from '@material-ui/core';

interface Props extends MenuProps {}
const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        backgroundColor: theme.backgroundColors?.white,
        overflow: 'unset',
        borderRadius: 10,
        '&::before': {
            display: 'block',
            position: 'absolute',
            left: 'calc(50% - 20px)',
            content: '""',
            padding: 20,
            transform: 'rotate(45deg)',
            backgroundColor: theme.backgroundColors?.white
        }
    },
    list: {
        padding: theme.spacing(1),
        '& .MuiMenuItem-root': {
            borderRadius: 10,
            minWidth: 120
        }
    }
}));

const RowMenu: React.FC<Props> = ({ children, ...rest }) => {
    const classes = useStyles();
    return (
        <Menu
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            classes={{
                paper: classes.paper,
                list: classes.list
            }}
            {...rest}
        >
            {children}
        </Menu>
    );
};

export default RowMenu;
