import { Prize } from '../../Models/Quiz';
import { uid } from 'Utils';

export const emptyPrize = (): Prize => {
    return {
        id: uid(),
        image: {
            url: '',
            name: '',
            size: 0,
            dimensions: '',
            date: ''
        },
        name: '',
        prizeType: ''
    };
};

const isImageEmpty = (image: Prize['image']) => {
    return !image?.url;
};

export const isEmptyPrize = (prize: Prize) => {
    return !prize.name && isImageEmpty(prize.image);
};
