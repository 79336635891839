import React from 'react';
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
    Dialog,
    DialogProps,
    DialogContent,
    DialogActions,
    Button,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
    Grid,
    OutlinedInput
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import ModalTitle from '../ModalTitle/ModalTitle';
import { useForm, TValidations } from '../../../../hooks/useForm';

import { ModalsEnum } from '../../../../types/Modal';
import { RolesEnum, Users } from '../../../../types/Users';
import { useCallback } from 'react';

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: 15,
            paddingTop: 4,
            paddingBottom: 16,
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: theme.backgroundColors?.primary
        },
        dialog: {
            borderRadius: 15,
            paddingLeft: theme.spacing(14),
            paddingRight: theme.spacing(14),
            paddingTop: theme.spacing(9),
            paddingBottom: theme.spacing(9)
        },
        dialogHeder: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: theme.spacing(1),
            '& > button': {
                alignSelf: 'end'
            }
        },
        dialogTitle: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginTop: theme.spacing(1)
        },
        dialogContent: {
            padding: theme.spacing(3)
        },
        dialogActions: {
            flexDirection: 'column',
            textAlign: 'center',
            '& #create-update-user-disclaimer': {
                color: theme.text?.secondary,
                fontSize: '0.8rem',
                maxWidth: 413,
                marginTop: theme.spacing(2)
            },
            '& .MuiButtonBase-root': {
                width: 220
            }
        },

        inputLable: {
            marginBottom: theme.spacing(1)
        },
        inputError: {
            color: theme.palette.error.main
        }
    });

type TMode = ModalsEnum.CREATE_USER | ModalsEnum.UPDATE_USER;
type TFormFields = Pick<
    Users.IUser,
    'firstName' | 'lastName' | 'email' | 'companyEmployeeId' | 'roles'
>;

type TLabelsMap = {
    title: string;
    submitButton: string;
    dismissButtom: string;
};

interface Props extends WithStyles<typeof styles>, Omit<DialogProps, 'classes'> {
    mode: TMode;
    data: Users.IUser;
    onSubmitModal: (data: any) => void;
    onDismiss: () => void;
}

const permissionMap: any = {
    [RolesEnum.SuperUser]: { key: 'SuperUser', display: 'Trivio and XC - Super Admin' },
    [RolesEnum.HuddleGameSuperAdmin]: {
        key: 'HuddleGameSuperAdmin',
        display: 'Trivio - Admin'
    },
    [RolesEnum.HuddleGameAdmin]: { key: 'HuddleGameAdmin', display: 'Trivio - User' },
    [RolesEnum.CustomerGameSuperAdmin]: {
        key: 'CustomerGameSuperAdmin',
        display: 'XC - Admin'
    },
    [RolesEnum.CustomerGameAdmin]: { key: 'CustomerGameAdmin', display: 'XC - CARS' },
    [RolesEnum.Visitor]: { key: 'Visitor', display: 'XC - Guest/Visitor' }
};

const labels: Record<TMode, TLabelsMap> = {
    [ModalsEnum.CREATE_USER]: {
        title: 'Add A New User',
        submitButton: 'Add User',
        dismissButtom: 'Discard'
    },
    [ModalsEnum.UPDATE_USER]: {
        title: 'Update User',
        submitButton: 'Save Changes',
        dismissButtom: 'Discard'
    }
};

const initialState = {
    id: '',
    firstName: '',
    lastName: '',
    companyId: '',
    companyEmployeeId: '',
    userName: '',
    email: '',
    roles: '',
    avatarUrl: ''
};

const validations: TValidations<TFormFields> = {
    firstName: {
        required: true
    },
    lastName: {
        required: true
    },
    email: {
        pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: 'Email is incorrect'
        }
    },
    // companyEmployeeId: {
    //     required: false
    // },
    roles: {
        required: true
    }
};
const UserModal: React.FC<Props> = ({ classes, open, data, mode, onSubmitModal, onDismiss }) => {
    const { values, errors, handleInput, setInput, isFormValid, setInitalValues } = useForm<TFormFields>(
        initialState,
        validations,
        open ? data : undefined
    );

    const handlePermissionsInput = useCallback((e) => {
        const {name, value} = e.target;
        let filteredValue = value.filter((el: any) => el !== '')
        if (values.roles.includes(RolesEnum.SuperUser) && filteredValue.includes(RolesEnum.SuperUser)) filteredValue = filteredValue.filter((el: any) => el !== RolesEnum.SuperUser)
        if (filteredValue.includes(RolesEnum.SuperUser)) filteredValue = [RolesEnum.SuperUser]
        setInput({name, value: filteredValue.length > 1 ? filteredValue.join(', ') : filteredValue.length === 0 ? '' : filteredValue[0]})
    }, [values, setInput])

    const onClose = () => {
        setInitalValues();
        onDismiss();
    };
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setInitalValues();
        onSubmitModal(values);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="create-update-user-title"
            aria-describedby="create-update-user-disclaimer"
            PaperProps={{
                classes: {
                    root: classes.paper
                }
            }}
        >
            <ModalTitle
                titleId="create-update-user-title"
                title={labels[mode].title}
                handleClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Grid
                    container
                    spacing={3}
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={onSubmit}
                >
                    <Grid item xs={6}>
                        <InputLabel
                            htmlFor="create-update-user-firstName"
                            className={classes.inputLable}
                        >
                            First Name
                        </InputLabel>
                        <OutlinedInput
                            id="create-update-user-firstName"
                            name="firstName"
                            fullWidth
                            placeholder="Enter First Name"
                            value={values.firstName}
                            onChange={handleInput}
                            disabled
                        />
                        {errors.firstName && (
                            <div className={classes.inputError}>{errors.firstName}</div>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel
                            htmlFor="create-update-user-lastName"
                            className={classes.inputLable}
                        >
                            Last Name
                        </InputLabel>
                        <OutlinedInput
                            id="create-update-user-lastName"
                            name="lastName"
                            fullWidth
                            placeholder="Enter Last Name"
                            value={values.lastName}
                            onChange={handleInput}
                            disabled
                        />
                        {errors.lastName && (
                            <div className={classes.inputError}>{errors.lastName}</div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel
                            htmlFor="create-update-user-email"
                            className={classes.inputLable}
                        >
                            Email Address
                        </InputLabel>
                        <OutlinedInput
                            id="create-update-user-email"
                            name="email"
                            fullWidth
                            placeholder="Enter Email Address"
                            value={values.email}
                            onChange={handleInput}
                            disabled
                        />
                        {errors.email && <div className={classes.inputError}>{errors.email}</div>}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <InputLabel
                            htmlFor="create-update-user-empoyeeId"
                            className={classes.inputLable}
                        >
                            Employee ID
                        </InputLabel>
                        <OutlinedInput
                            id="create-update-user-empoyeeId"
                            name="companyEmployeeId"
                            fullWidth
                            placeholder="Enter Employee ID"
                            value={values.companyEmployeeId}
                            onChange={handleInput}
                        />
                        {errors.companyEmployeeId && (
                            <div className={classes.inputError}>{errors.companyEmployeeId}</div>
                        )}
                    </Grid> */}
                    <Grid item xs={12}>
                        <InputLabel id="create-update-user-role" className={classes.inputLable}>
                            Account Role
                        </InputLabel>
                        <Select
                            labelId="create-update-user-role"
                            fullWidth
                            value={values.roles.replace(/\s+/g, '').split(',')}
                            multiple
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }
                            }}
                            variant="outlined"
                            inputProps={{
                                name: 'roles',
                                onChange: handlePermissionsInput
                            }}
                            displayEmpty
                            renderValue={() => {
                                if (
                                    values.roles &&
                                    typeof values.roles === 'string' &&
                                    values.roles.replace(/\s+/g, '').split(',').length > 0 &&
                                    values.roles.replace(/\s+/g, '').split(',').every((el) => !!permissionMap[el])
                                ) {
                                    return values.roles
                                    .replace(/\s+/g, '')
                                    .split(',').length ? values.roles
                                        .replace(/\s+/g, '')
                                        .split(',')
                                        .map((el: any) => permissionMap[el].display)
                                        .join(', ') : permissionMap[values.roles
                                        .replace(/\s+/g, '')];
                                }
                                return <div className="MuiFormLabel-root">Select Account Role</div>;
                            }}
                            IconComponent={ExpandMoreIcon}
                        >
                            {Object.keys(RolesEnum).filter(el => el !== RolesEnum.Visitor).map((key) => {
                                return (
                                    <MenuItem
                                        key={key}
                                        value={key}
                                    >
                                        <Checkbox checked={values.roles.includes(key)} />
                                        <ListItemText primary={`${permissionMap[key]?.display}`} />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {errors.roles && <div className={classes.inputError}>{errors.roles}</div>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={onSubmit}
                    color="primary"
                    variant="contained"
                    disabled={!isFormValid()}
                >
                    {labels[mode].submitButton}
                </Button>
                {/* <Typography id="create-update-user-disclaimer">
                    This can be where the disclaimer text goes that explains what happens after user
                    presses "Add User".
                </Typography> */}
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(UserModal);
