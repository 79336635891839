import moment from 'moment-timezone';
import { Meetings } from 'types/Meetings';
import { EventPreviewProps } from '../components/EventPreview';
import { Durations } from '../constants';

export const meetingResponseToProps = (meeting: Meetings.TMeeting, preview: boolean): EventPreviewProps => {
  const participants: string[] = [];
  if (meeting.presentParticipants) {
    meeting.presentParticipants.forEach(p => participants.push(p.email));
  }
  if (meeting.invitedParticipants) {
    meeting.invitedParticipants.forEach(p => participants.push(p.email));
  }
  return {
    id: meeting.id,
    code: meeting.code,
    logoImageData: meeting.invitationConfiguration.invitationImage ? meeting.invitationConfiguration.invitationImage.logo : null,
    heroImageData: meeting.invitationConfiguration.invitationImage ? meeting.invitationConfiguration.invitationImage.hero : null,
    eventName: meeting.name,
    eventDescription: meeting.description,
    backgroundColor: meeting.invitationConfiguration.backgroundColor,
    textColor: meeting.invitationConfiguration.textColor,
    accentColor: meeting.invitationConfiguration.buttonColor,
    buttonTextColor: meeting.invitationConfiguration.buttonTextColor,
    provider: meeting.platform,
    selectedDate: moment(meeting.time).toDate(),
    selectedTime: moment(meeting.time).format('h:mm A'),
    selectedDuration: moment().utc().startOf('day').add(meeting.durationInMinutes, 'minutes').format('H:mm'),
    selectedTimeZone: meeting.timezone,
    participants: participants,
    previewShow: preview
  };
};

export const durationsToMinutes = (duration: string): number => {
  const minutes = [20, 30, 45, 60, 75, 90, 105, 120];
  const index = Durations.indexOf(duration);
  return index > -1 ? minutes[index] : 0;
}
