import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton
} from '@material-ui/core';

import {
    IconDefinition,
    faEllipsisV,
    faVideo,
    faGamepad,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as ComcastSvg } from '../../../assets/img/comcast.svg';

import ListItemLink from './ListItemLink';
import { routes } from '../../../config';
import { IAppState } from 'types';
import { RolesEnum, ShowType } from 'types/Enums';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minWidth: 215,
            backgroundColor: theme.backgroundColors?.secondaryBlue,
            color: theme.text?.primaryBlue,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            // boxShadow: '2px 0px 9px 0px rgba(0,0,0,0.2)',
            zIndex: 2
        },
        header: {
            height: 90,
            '& .MuiTypography-root': {
                fontSize: 15,
                fontWeight: 600,
                color: theme.text?.primary
            }
        },
        groupHeder: {
            '& .MuiTypography-root': {
                fontSize: 14,
                fontWeight: 600,
                color: theme.text?.primary
            }
        },
        divider: {
            height: 1,
            border: theme.border?.row
        },
        list: {
            marginBottom: theme.spacing(2),
            '& .MuiListItem-root': {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1)
            }
        },
        schedulerHeader: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
            '& .back-button': {
                marginRight: theme.spacing(2)
            }
        },
        schedulerTitle: {
            '& .MuiTypography-root': {
                fontSize: 17,
                fontWeight: 500,
                color: theme.text?.black
            }
        },
        bottomBar: {
            bottom: 0,
            position: "absolute"
        },
        comcastImg: {
            paddingRight: 8
        }
    });

type TMenuItemConfig = {
    label: string;
    to: string;
    matchType: 'base' | 'exact';
    icon?: IconDefinition;
};

const menuItems3: TMenuItemConfig[] = [
    { label: 'Meetings', to: routes.scheduler, matchType: 'base', icon: faVideo },
    { label: 'Trivia', to: routes.quizCategories, matchType: 'base', icon: faGamepad },
];

interface Props extends WithStyles<typeof styles> { }

const SideNav: React.FC<Props> = ({ classes }) => {
    const location = useLocation();
    const showType = useSelector((state: IAppState) => state.auth.community);
    const userRole = useSelector((state: IAppState) => state.auth.user?.roles);
    
    const matchRoute = (itemHref: string, matchType: 'base' | 'exact'): boolean => {
        if (matchType === 'base') return location.pathname.split('/')[1] === itemHref.split('/')[1];
        if (matchType === 'exact') return location.pathname === itemHref;
        return false;
    };

    return (
        <aside className={classes.root}>
            <div style={{ position: 'fixed', left: 0, width: 215 }}>
                <ListItem className={classes.header} component="div">
                    <ComcastSvg title="Comcast" className={classes.comcastImg} />
                    <ListItemText primary="Comcast" />
                    <IconButton color="inherit">
                        <FontAwesomeIcon
                            icon={faEllipsisV}
                            style={{ width: 20, height: 20 }}
                            className="MuiSvgIcon-root MuiSvgIcon-colorPrimary"
                        />
                    </IconButton>
                </ListItem>
                {(showType === ShowType.ALL || showType === ShowType.COMMUNITY) &&
                    <>
                        <Divider className={classes.divider} />
                        <ListItem className={classes.groupHeder} component="div">
                            <ListItemText primary="Xfinity Communities" />
                        </ListItem>
                        <List className={classes.list} component="nav">
                            {menuItems3.map((item) => (
                                <ListItemLink
                                    icon={item.icon}
                                    key={item.label}
                                    primary={item.label}
                                    to={item.to}
                                    selected={matchRoute(item.to, item.matchType)}
                                />
                            ))}
                        </List>
                    </>
                }

                {(showType === ShowType.ALL || showType === ShowType.HUDDLE) &&
                    <>
                        <Divider className={classes.divider} />
                        <ListItem className={classes.groupHeder} component="div">
                            <ListItemText primary="TRIVIO" />
                        </ListItem>
                        <List className={classes.list} component="nav">
                            <ListItemLink
                                icon={faGamepad}
                                primary="Trivia"
                                to={routes.huddleCategories}
                                selected={matchRoute(routes.huddleCategories, 'base')}
                            />
                        </List>
                    </>
                }

                {(userRole === RolesEnum.SuperUser) &&
                    <>
                        <Divider className={classes.divider} />
                        <ListItem className={classes.groupHeder} component="div">
                            <ListItemText primary="Users" />
                        </ListItem>
                        <List className={classes.list} component="nav">
                            <ListItemLink
                                icon={faUser}
                                primary="User Management"
                                to={routes.users}
                                selected={matchRoute(routes.users, 'exact')}
                            />
                        </List>
                    </>
                }
            </div>
        </aside>
    );
};

export default withStyles(styles)(SideNav);
