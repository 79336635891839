import { Quizzes } from '../../types/Quizzes';
const initialState = {
    id: '',
    name: '',
    categoryId: '',
    description: '',
    avatarUrl: null,
    isPublic: true
} as Quizzes.TQuizDetailsFormFields;

const storeToState = (quiz: Quizzes.TDefinition | null): Quizzes.TQuizDetailsFormFields => {
    if (!quiz) return initialState;
    return {
        id: quiz.id,
        name: quiz.name,
        categoryId: quiz.category ? quiz.category.id : 'test',
        description: quiz.description,
        avatarUrl: quiz.avatarUrl,
        isPublic: quiz.isPublic
    };
};

const stateToStore = (quiz: Quizzes.TQuizDetailsFormFields): Quizzes.TQuizDetatils => {
    const { categoryId, ...rest } = quiz;
    return {
        ...rest,
        category: {
            id: categoryId
        }
    };
};

export const quizDetailsMapper = {
    getStoreTemplate: () => stateToStore(initialState),
    getStateTemplate: () => initialState,
    storeToState,
    stateToStore
};
