// import TeamProviderIcon from '../../assets/img/scheduler/teams.png';
import XurealProviderIcon from '../../assets/img/scheduler/xureal.png';
// import ZoomProviderIcon from '../../assets/img/scheduler/zoom.png';

export const VideoServiceProviders = [
  // { icon: TeamProviderIcon, name: 'Microsoft Teams' },
  { icon: XurealProviderIcon, name: 'Xureal' },
  // { icon: ZoomProviderIcon, name: 'Zoom' }
];

export const TimeZones = [
  { 'value': 'America/New_York', 'text': 'Eastern Time' },
  { 'value': 'America/Chicago', 'text': 'Central Time' },
  { 'value': 'America/Denver', 'text': 'Mountain Time' },
  { 'value': 'America/Los_Angeles', 'text': 'Pacific Time' },
  { 'value': 'Pacific/Midway', 'text': 'Midway Island, Samoa' },
  { 'value': 'Pacific/Pago_Pago', 'text': 'Pago Pago' },
  { 'value': 'Pacific/Honolulu', 'text': 'Hawaii' },
  { 'value': 'America/Anchorage', 'text': 'Alaska' },
  { 'value': 'America/Vancouver', 'text': 'Vancouver' },
  { 'value': 'America/Tijuana', 'text': 'Tijuana' },
  { 'value': 'America/Edmonton', 'text': 'Edmonton' },
  { 'value': 'America/Phoenix', 'text': 'Arizona' },
  { 'value': 'America/Mazatlan', 'text': 'Mazatlan' },
  { 'value': 'America/Winnipeg', 'text': 'Winnipeg' },
  { 'value': 'America/Regina', 'text': 'Saskatchewan' },
  { 'value': 'America/Mexico_City', 'text': 'Mexico City' },
  { 'value': 'America/Guatemala', 'text': 'Guatemala' },
  { 'value': 'America/El_Salvador', 'text': 'El Salvador' },
  { 'value': 'America/Managua', 'text': 'Managua' },
  { 'value': 'America/Costa_Rica', 'text': 'Costa Rica' },
  { 'value': 'America/Montreal', 'text': 'Montreal' },
  { 'value': 'America/Indianapolis', 'text': 'Indiana (East)' },
  { 'value': 'America/Panama', 'text': 'Panama' },
  { 'value': 'America/Bogota', 'text': 'Bogota' },
  { 'value': 'America/Lima', 'text': 'Lima' },
  { 'value': 'America/Halifax', 'text': 'Halifax' },
  { 'value': 'America/Puerto_Rico', 'text': 'Puerto Rico' },
  { 'value': 'America/Caracas', 'text': 'Caracas' },
  { 'value': 'America/Santiago', 'text': 'Santiago' },
  { 'value': 'America/St_Johns', 'text': 'Newfoundland and Labrador' },
  { 'value': 'America/Montevideo', 'text': 'Montevideo' },
  { 'value': 'America/Araguaina', 'text': 'Brasilia' },
  { 'value': 'America/Argentina/Buenos_Aires', 'text': 'Buenos Aires, Georgetown' },
  { 'value': 'America/Godthab', 'text': 'Greenland' },
  { 'value': 'America/Sao_Paulo', 'text': 'Sao Paulo' },
  { 'value': 'Atlantic/Azores', 'text': 'Azores' },
  { 'value': 'Canada/Atlantic', 'text': 'Atlantic Time (Canada)' },
  { 'value': 'Atlantic/Cape_Verde', 'text': 'Cape Verde Islands' },
  { 'value': 'UTC', 'text': 'Universal Time UTC' },
  { 'value': 'Etc/Greenwich', 'text': 'Greenwich Mean Time' },
  { 'value': 'Europe/Belgrade', 'text': 'Belgrade, Bratislava, Ljubljana' },
  { 'value': 'CET', 'text': 'Sarajevo, Skopje, Zagreb' },
  { 'value': 'Atlantic/Reykjavik', 'text': 'Reykjavik' },
  { 'value': 'Europe/Dublin', 'text': 'Dublin' },
  { 'value': 'Europe/London', 'text': 'London' },
  { 'value': 'Europe/Lisbon', 'text': 'Lisbon' },
  { 'value': 'Africa/Casablanca', 'text': 'Casablanca' },
  { 'value': 'Africa/Nouakchott', 'text': 'Nouakchott' },
  { 'value': 'Europe/Oslo', 'text': 'Oslo' },
  { 'value': 'Europe/Copenhagen', 'text': 'Copenhagen' },
  { 'value': 'Europe/Brussels', 'text': 'Brussels' },
  { 'value': 'Europe/Berlin', 'text': 'Amsterdam, Berlin, Rome, Stockholm, Vienna' },
  { 'value': 'Europe/Helsinki', 'text': 'Helsinki' },
  { 'value': 'Europe/Amsterdam', 'text': 'Amsterdam' },
  { 'value': 'Europe/Rome', 'text': 'Rome' },
  { 'value': 'Europe/Stockholm', 'text': 'Stockholm' },
  { 'value': 'Europe/Vienna', 'text': 'Vienna' },
  { 'value': 'Europe/Luxembourg', 'text': 'Luxembourg' },
  { 'value': 'Europe/Paris', 'text': 'Paris' },
  { 'value': 'Europe/Zurich', 'text': 'Zurich' },
  { 'value': 'Europe/Madrid', 'text': 'Madrid' },
  { 'value': 'Africa/Bangui', 'text': 'West Central Africa' },
  { 'value': 'Africa/Algiers', 'text': 'Algiers' },
  { 'value': 'Africa/Tunis', 'text': 'Tunis' },
  { 'value': 'Africa/Harare', 'text': 'Harare, Pretoria' },
  { 'value': 'Africa/Nairobi', 'text': 'Nairobi' },
  { 'value': 'Europe/Warsaw', 'text': 'Warsaw' },
  { 'value': 'Europe/Prague', 'text': 'Prague Bratislava' },
  { 'value': 'Europe/Budapest', 'text': 'Budapest' },
  { 'value': 'Europe/Sofia', 'text': 'Sofia' },
  { 'value': 'Europe/Istanbul', 'text': 'Istanbul' },
  { 'value': 'Europe/Athens', 'text': 'Athens' },
  { 'value': 'Europe/Bucharest', 'text': 'Bucharest' },
  { 'value': 'Asia/Nicosia', 'text': 'Nicosia' },
  { 'value': 'Asia/Beirut', 'text': 'Beirut' },
  { 'value': 'Asia/Damascus', 'text': 'Damascus' },
  { 'value': 'Asia/Jerusalem', 'text': 'Jerusalem' },
  { 'value': 'Asia/Amman', 'text': 'Amman' },
  { 'value': 'Africa/Tripoli', 'text': 'Tripoli' },
  { 'value': 'Africa/Cairo', 'text': 'Cairo' },
  { 'value': 'Africa/Johannesburg', 'text': 'Johannesburg' },
  { 'value': 'Europe/Moscow', 'text': 'Moscow' },
  { 'value': 'Asia/Baghdad', 'text': 'Baghdad' },
  { 'value': 'Asia/Kuwait', 'text': 'Kuwait' },
  { 'value': 'Asia/Riyadh', 'text': 'Riyadh' },
  { 'value': 'Asia/Bahrain', 'text': 'Bahrain' },
  { 'value': 'Asia/Qatar', 'text': 'Qatar' },
  { 'value': 'Asia/Aden', 'text': 'Aden' },
  { 'value': 'Asia/Tehran', 'text': 'Tehran' },
  { 'value': 'Africa/Khartoum', 'text': 'Khartoum' },
  { 'value': 'Africa/Djibouti', 'text': 'Djibouti' },
  { 'value': 'Africa/Mogadishu', 'text': 'Mogadishu' },
  { 'value': 'Asia/Dubai', 'text': 'Dubai' },
  { 'value': 'Asia/Muscat', 'text': 'Muscat' },
  { 'value': 'Asia/Baku', 'text': 'Baku, Tbilisi, Yerevan' },
  { 'value': 'Asia/Kabul', 'text': 'Kabul' },
  { 'value': 'Asia/Yekaterinburg', 'text': 'Yekaterinburg' },
  { 'value': 'Asia/Tashkent', 'text': 'Islamabad, Karachi, Tashkent' },
  { 'value': 'Asia/Calcutta', 'text': 'India' },
  { 'value': 'Asia/Kathmandu', 'text': 'Kathmandu' },
  { 'value': 'Asia/Novosibirsk', 'text': 'Novosibirsk' },
  { 'value': 'Asia/Almaty', 'text': 'Almaty' },
  { 'value': 'Asia/Dacca', 'text': 'Dacca' },
  { 'value': 'Asia/Krasnoyarsk', 'text': 'Krasnoyarsk' },
  { 'value': 'Asia/Dhaka', 'text': 'Astana, Dhaka' },
  { 'value': 'Asia/Bangkok', 'text': 'Bangkok' },
  { 'value': 'Asia/Saigon', 'text': 'Vietnam' },
  { 'value': 'Asia/Jakarta', 'text': 'Jakarta' },
  { 'value': 'Asia/Irkutsk', 'text': 'Irkutsk, Ulaanbaatar' },
  { 'value': 'Asia/Shanghai', 'text': 'Beijing, Shanghai' },
  { 'value': 'Asia/Hong_Kong', 'text': 'Hong Kong' },
  { 'value': 'Asia/Taipei', 'text': 'Taipei' },
  { 'value': 'Asia/Kuala_Lumpur', 'text': 'Kuala Lumpur' },
  { 'value': 'Asia/Singapore', 'text': 'Singapore' },
  { 'value': 'Australia/Perth', 'text': 'Perth' },
  { 'value': 'Asia/Yakutsk', 'text': 'Yakutsk' },
  { 'value': 'Asia/Seoul', 'text': 'Seoul' },
  { 'value': 'Asia/Tokyo', 'text': 'Osaka, Sapporo, Tokyo' },
  { 'value': 'Australia/Darwin', 'text': 'Darwin' },
  { 'value': 'Australia/Adelaide', 'text': 'Adelaide' },
  { 'value': 'Asia/Vladivostok', 'text': 'Vladivostok' },
  { 'value': 'Pacific/Port_Moresby', 'text': 'Guam, Port Moresby' },
  { 'value': 'Australia/Brisbane', 'text': 'Brisbane' },
  { 'value': 'Australia/Sydney', 'text': 'Canberra, Melbourne, Sydney' },
  { 'value': 'Australia/Hobart', 'text': 'Hobart' },
  { 'value': 'Asia/Magadan', 'text': 'Magadan' },
  { 'value': 'SST', 'text': 'Solomon Islands' },
  { 'value': 'Pacific/Noumea', 'text': 'New Caledonia' },
  { 'value': 'Asia/Kamchatka', 'text': 'Kamchatka' },
  { 'value': 'Pacific/Fiji', 'text': 'Fiji Islands, Marshall Islands' },
  { 'value': 'Pacific/Auckland', 'text': 'Auckland, Wellington' },
  { 'value': 'Asia/Kolkata', 'text': 'Mumbai, Kolkata, New Delhi' },
  { 'value': 'Europe/Kiev', 'text': 'Kiev' },
  { 'value': 'America/Tegucigalpa', 'text': 'Tegucigalpa' },
  { 'value': 'Pacific/Apia', 'text': 'Independent State of Samoa' }
];

export const Durations: string[] = [
  '0:20', '0:30', '0:45', '1:00', '1:15', '1:30', '1:45', '2:00'
];

export const PAGE_SIZE = 10;