import {IAppState} from "../../../types";
import {createSelector} from "reselect";
import {ModalsEnum} from "../../../types/Enums";
import {Quizzes} from "../../../types/Quizzes";
import {questionMapper, quizDetailsMapper, rewardMapper} from "../../../redux/dataMappers";

const quizzesLoaderSelector = (state: IAppState) => Boolean(state.ui.loaders['quizzes-table']);
const categoriesLoaderSelector = (state: IAppState) =>  Boolean(state.ui.loaders['quizzes-categories']);
const quizLoaderSelector = (state: IAppState) => Boolean(state.ui.loaders['quiz']);
const modalKeySelector = (state: IAppState) => state.modal.opened;
const modalSelector = (state: IAppState) => ({
    modalKey: state.modal.opened,
    modalData: state.modal.data
});
const quizzesSelector = (state: IAppState) => ({
    quizzesData: state.quizzes.data,
    totalCount: state.quizzes.totalElementsCount,
    tableQueryParams: state.quizzes.tableQueryParams,
    categories: state.quizzes.categories.data,
    totalCountCats: state.quizzes.categories.totalElementsCount,
    tableQueryParamsCats: state.quizzes.categories.tableQueryParams
});
const quizSelector = (state: IAppState) => state.quizzes.quiz;
const quizQuestionsSelector = (state: IAppState) => state.quizzes.pendingQuestions;
const quizImagesSelector = (state: IAppState) => state.quizzes.quizImages;
const quizRewardsSelector = (state: IAppState) => state.quizzes.pendingRewards;
const navigationSelector = (state: IAppState) => state.ui.navigation.to;
const tempAvatarSelector = (state: IAppState) => state.quizzes.tempAvatar;
const meetingIdSelector = (state: IAppState) => state.quizzes.triviaMeetingId;
const quizPreviewSelector = (state: IAppState) => state.quizzes.preview;
const userSelector = (state: IAppState) => state.auth.user;

export const quizzesPageSelector = createSelector(
    [modalKeySelector, quizzesSelector, quizzesLoaderSelector, navigationSelector],

    (modalKey, {quizzesData, totalCount, tableQueryParams}, loadingQuizzes, goto) => {
        const confirmModalOpened = modalKey === ModalsEnum.CONFIRM;

        const quizzes: Quizzes.TQuizTableRecord[] = quizzesData.map((quiz) => ({
            id: quiz.id,
            name: quiz.name,
            category: {
                id: quiz.category.id,
                name: quiz.category?.name || 'undefined'
            },
            createOn: quiz.createdOnUtc,
            status: quiz.gameDefinitionState,
            visibility: quiz.isPublic ? 'Public' : 'Private',
            createdBy: {
                id: quiz.createdBy.id,
                name: quiz.createdBy.name
            },
            allFields: {...quiz},
            tableData: {
                id: parseInt(quiz.id),
                checked: false
            }
        }));

        return {
            modalKey,
            confirmModalOpened,
            quizzes,
            totalCount,
            tableQueryParams,
            loadingQuizzes,
            goto
        };
    }
);
/** Quiz Page */
export const quizToEditSelector = createSelector(
    [quizSelector, quizQuestionsSelector, quizRewardsSelector, quizLoaderSelector, quizImagesSelector, quizPreviewSelector],
    (quiz, questions, rewards, loadingQuiz, quizImages, quizPreview) => {
        const quizDetails = quizDetailsMapper.storeToState(quiz);
        const quizQuestions = questions.map((q) => questionMapper.storeToState(q));
        const quizRewards = rewards.map((r) => rewardMapper.storeToState(r));
        return {
            quizDetails,
            quizQuestions,
            quizRewards,
            loadingQuiz,
            quizImages,
            quizPreview
        };
    }
);
export const quizPageSelector = createSelector(
    [modalKeySelector, quizzesSelector, categoriesLoaderSelector, navigationSelector],
    (modalKey, {categories}, loadingCategories, goto) => {
        const categoryModalOpened = modalKey === ModalsEnum.CREATE_CATEGORY;
        const categoryModalMode = ModalsEnum.CREATE_CATEGORY as ModalsEnum.CREATE_CATEGORY;
        return {
            modalKey,
            categoryModalMode,
            categoryModalOpened,
            loadingCategories,
            categories,
            goto
        };
    }
);
/** Categories Page */
export const catsPageSelector = createSelector(
    [modalSelector, quizzesSelector, categoriesLoaderSelector],
    (
        {modalKey, modalData},
        {categories, totalCountCats, tableQueryParamsCats},
        loadingCategories
    ) => {
        const categoryModalOpened = modalKey === ModalsEnum.CREATE_CATEGORY || modalKey === ModalsEnum.UPDATE_CATEGORY;
        const confirmModalOpened = modalKey === ModalsEnum.CONFIRM;
        const categoryModalMode: ModalsEnum.UPDATE_CATEGORY | ModalsEnum.CREATE_CATEGORY =
            modalKey === ModalsEnum.UPDATE_CATEGORY
                ? ModalsEnum.UPDATE_CATEGORY
                : ModalsEnum.CREATE_CATEGORY;

        return {
            modalKey,
            modalData,
            categoryModalMode,
            categoryModalOpened,
            confirmModalOpened,
            data: categories,
            totalCount: totalCountCats,
            tableQueryParams: tableQueryParamsCats,
            loadingCategories
        };
    }
);

export const quizAvatarSelector = createSelector([tempAvatarSelector], (url) => {
    return {
        tempURL: url
    };
});

export const meetingCodeSelector = createSelector([meetingIdSelector], (id) => {
    return {
        meetingId: id
    }
});

export const userInfoSelector = createSelector([userSelector], (user) => {
    return {
        user
    }
});
