import { SnackbarEnum, LoadersEnum, NavigationEnum } from './Enums';
import { IAction, IActionPayload, TypeOfProp } from './utils';

/** -> Snackbar Action Types */
export const SnackbarActions = SnackbarEnum;
/** -> Loaders Action Types */
export const LoadersActions = LoadersEnum;
/** -> Navigation Action Types */
export const NavigationActions = NavigationEnum;

export declare namespace UI {
    export type TSnackbars = 'success' | 'error' | 'info';
    export type TLoaders = 'users' | 'quizzes-table' | 'quizzes-categories' | 'quiz' | 'meetings-list' | 'meeting' | 'image-upload' | 'auth';
    export type TRoute = string;
    export type TState = {
        snackbar: {
            opened: boolean;
            data: {
                message: string;
                type: TSnackbars;
            };
        };
        loaders: {
            [key in TLoaders]: boolean;
        };
        navigation: {
            to: TRoute;
        };
    };

    export namespace TActions {
        export namespace Snackbar {
            export type Show = IActionPayload<SnackbarEnum.SHOW, TypeOfProp<TState, 'snackbar'>>;
            export type Hide = IAction<SnackbarEnum.HIDE>;
        }
        export namespace Loaders {
            export type Show = IActionPayload<LoadersEnum.SHOW, TLoaders>;
            export type Hide = IActionPayload<LoadersEnum.HIDE, TLoaders>;
        }

        export namespace Navigation {
            export type GoTo = IActionPayload<NavigationEnum.GO_TO, TRoute>;
            export type GoBack = IAction<NavigationEnum.GO_BACK>;
            export type Clear = IAction<NavigationEnum.CLEAR>;
        }

        export type AllReducer =
            | Loaders.Show
            | Loaders.Hide
            | Snackbar.Show
            | Snackbar.Hide
            | Navigation.GoTo
            | Navigation.Clear;
    }
}
