import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core';
import { Snackbar} from 'Components/Shared';
import AppRouter from "./AppRouter";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            minHeight: '100vh',
            backgroundColor: theme.backgroundColors?.primary,
            overflow: 'hidden',
            [theme.breakpoints.up('sm')]: {
                display: 'flex'
            }
        }
    });

const App: React.FC<WithStyles> = ({ classes }) => {

    return (
        <Router>
            <div className={classes.root}>
                <AppRouter/>
            </div>
            <Snackbar />
        </Router>
    );
};

export default withStyles(styles)(App);
