/** -> User Roles */
export enum RolesEnum {
    SuperUser = 'SuperUser',
    HuddleGameAdmin = 'HuddleGameAdmin',
    HuddleGameSuperAdmin = 'HuddleGameSuperAdmin',
    CustomerGameAdmin = 'CustomerGameAdmin',
    CustomerGameSuperAdmin = 'CustomerGameSuperAdmin',
    AllSuperAdmin = 'CustomerGameSuperAdmin, HuddleGameSuperAdmin',
    Visitor = 'Visitor'
}
/** -> Game Definition State*/
export enum GameDefinitionState {
    Active = 'Active',
    Disabled = 'Disabled',
    SoftDeleted = 'SoftDeleted'
}
/** -> Question Types */
export enum QuestionsEnum {
    SingleChoice = 'SingleChoice',
    // MultipleChoice = 'MultipleChoice',
    // TextInput = 'TextInput',
    // Dropdown = 'Dropdown',
    // Ranking = 'Ranking',
    // StarRating = 'StarRating',
    // RatingScale = 'RatingScale'
}
/** -> Answer Formats */
export enum AnswerFormatEnum {
    Text = 'Text',
    Image = 'Image'
}

/** -> Question Formats */
export enum QuestionFormatEnum {
    Text = 'Text',
    Image = 'Image'
}

/** -> Prize Types */
export enum PrizeTypeEnum {
    TourMiles = 'Tour Miles',
    Merchandise = 'Merchandise',
    Tokens = 'Tokens'
}

/** -> AUthentication Action Types */
export enum AuthTypesEmun {
    LOGIN = '[auth] LOGIN',
    LOGOUT = '[auth] LOGOUT',
    SUCCESS = '[auth] LOGIN_SUCCESS',
    CHANGE_PASSWORD = '[auth] CHANGE_PASSWORD',
    SEND_RESET_PASSWORD_EMAIL = '[auth] SEND_RESET_PASSWORD_EMAIL',
    RESET_PASSWORD = '[auth] RESET_PASSWORD',
    REFRESH_TOKEN = '[auth] START_REFRESH_TOKEN_TASK',
    CLEAR = '[auth] CLEAR STORE',
    SSO_LOGIN = '[auth] SSO_LOGIN'
}

/** -> Users Action Types */
export enum UsersTypesEnum {
    GET = '[users] GET',
    GET_UESR = '[users] GET_UESR',
    CREATE = '[users] CREATE',
    UPDATE = '[users] UPDATE',
    DELETE = '[users] DELETE',
    SET = '[users] SET',
    SET_QUERY_PARAMS = '[users] SET_QUERY_PARAMS'
}

/** -> Modal Action Types */
export enum ModalTypesEmun {
    OPEN = '[modal] OPEN',
    CLOSE = '[modal] CLOSE'
}

/** -> Types of Modal Instances */
export enum ModalsEnum {
    CONFIRM = 'CONFIRM_MODAL',
    UPDATE_USER = 'UPDATE_USER_MODAL',
    CREATE_USER = 'CREATE_USER_MODAL',
    CREATE_CATEGORY = 'CREATE_CATEGORY',
    UPDATE_CATEGORY = 'UPDATE_CATEGORY'
}

/** -> Snackbar Action Types */
export enum SnackbarEnum {
    SHOW = '[ui] SHOW_SNACKBAR',
    HIDE = '[ui] HIDE_SNACKBAR'
}

/** -> Loaders Action Types */
export enum LoadersEnum {
    SHOW = '[ui] SHOW_LOADER',
    HIDE = '[ui] HIDE_LOADER'
}

/** -> Navigation Action Types */
export enum NavigationEnum {
    GO_TO = '[ui] NAVIGATION TO',
    GO_BACK = '[ui] NAVIGATION BACK',
    CLEAR = '[ui] NAVIGATION CLEAR STORE'
}

export enum EventForm {
    EventDetails,
    CustomInvitation
}

export enum ColorType {
    BackgroundColor,
    TextColor,
    AccentColor,
    ButtonTextColor
}

export enum ImageUpload {
    HeroImage,
    LogoImage
}

export enum CalendarTab {
    Overview,
    Trivia,
    Prizes
}

export enum TriviaBoardState {
    Opened,
    Add,
    Change
}

export enum EventImageType {
    HeroImage = 'HeroImage',
    LogoImage = 'LogoImage'
}

export enum ShowType {
    ALL,
    COMMUNITY,
    HUDDLE,
    SAME
}

export enum ValidStatus {
    NONE,
    VALID,
    INVALID
}
