import { useRef, useEffect, useCallback } from 'react';

const useIsMounted = () => {
    const ref = useRef(true);
    useEffect(() => {
        return () => {
            ref.current = false;
        };
    }, []);
    return useCallback(() => ref.current, []);
};
export default useIsMounted;
