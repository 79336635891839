import React from 'react';
import { DialogTitle, IconButton, makeStyles, Theme, IconButtonProps } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
    dialogHeder: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        '& > button': {
            alignSelf: 'end'
        }
    },
    dialogTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    }
}));

interface ModalTypeProps extends IconButtonProps {
    handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    title: string;
    titleId?: string;
}

const ModalTitle: React.FC<ModalTypeProps> = ({ handleClose, title, titleId }) => {
    const classes = useStyles();
    return (
        <div className={classes.dialogHeder}>
            <DialogTitle id={titleId} disableTypography>
                <div className={classes.dialogTitle}>{title}</div>
            </DialogTitle>
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </div>
    );
};

export default ModalTitle;
