import { put, call, delay, fork, take, cancel, cancelled } from 'redux-saga/effects';
import {
    usersApiRequest,
    handleGenerateError,
    getLocalToken,
    setAuthToken,
    isTokenExpired,
    getRefreshTokenInterval,
    quizzesApiRequest
} from '../api';

import { AuthActions, Auth } from '../../types/Auth';

import { routes } from '../../config';

import {
    showLoader,
    hideLoader,
    showSnackbar,
    authSuccess,
    authRefreshToken,
    authClearStore,
    navGoTo,
    navClearStore
} from '../actions';
import { AuthService } from "../../Core/Services";
import { ShowType } from 'types/Enums';

const { authenticate, refreshToken } = AuthService.api()

function* handleRefreshToken(setUser: boolean) {

    try {
        const response = yield call(refreshToken);
        yield call(setAuthToken, response.data.accessToken);
        const refreshInterval = yield call(getRefreshTokenInterval, response.data.accessToken);

        const nPResponse = yield call(quizzesApiRequest, {
            method: 'GET',
            url: `/game/definition/permissions`
        });
        const hPResponse = yield call(quizzesApiRequest, {
            method: 'GET',
            url: `/game/definition/permissions?isHuddle=true`
        });

        if (setUser) yield put(authSuccess({
            ...response.data,
            community: ShowType.SAME,
            normalPermission: nPResponse.data,
            huddlePermission: hPResponse.data
        }));
        return { interval: refreshInterval, error: null };
    } catch (error) {
        console.error('CATCHED handleRefresToken Error: ', error);
        return { interval: null, error: error };
    }
}

export function* checkLocalToken() {
    const accessToken = getLocalToken();
    if (accessToken !== null) {
        if (isTokenExpired(accessToken)) {
            // Token axpired
            yield call(setAuthToken, null);
        } else {
            yield put(showLoader('auth'));
            // Token is valid
            yield call(setAuthToken, accessToken);
            // Refresh token and set user data from response
            const { interval, error } = yield call(handleRefreshToken, true);
            yield put(hideLoader('auth'));
            if (interval && !error) {
                // Start refresh interval
                yield delay(interval);
                yield put(authRefreshToken());
            }
        }
    }
}

function* bgSyncToken() {
    let doSync = true;
    try {
        while (doSync) {
            const { interval, error } = yield call(handleRefreshToken, false);
            if (error) doSync = false;
            if (interval) {
                doSync = true;
                yield delay(interval); // 29 mins or calculate from token
            }
        }
    } finally {
        yield cancelled();
    }
}

export function* handleSyncTokenTask() {
    while (yield take([AuthActions.SUCCESS, AuthActions.REFRESH_TOKEN])) {
        const bgSyncTask = yield fork(bgSyncToken);
        yield take(AuthActions.LOGOUT);
        yield cancel(bgSyncTask);
    }
}

export function* authAPISaga(action: Auth.TActions.APIActions) {
    try {
        if (action.type === AuthActions.LOGIN) {
            yield put(showLoader('auth'));

            const response = yield call(authenticate, action.payload)
            const { accessToken } = response.data;

            yield call(setAuthToken, accessToken);

            const nPResponse = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/definition/permissions`
            });
            const hPResponse = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/definition/permissions?isHuddle=true`
            });

            yield put(authSuccess({
                ...response.data,
                community: ShowType.ALL,
                normalPermission: nPResponse.data,
                huddlePermission: hPResponse.data
            }));
            yield put(hideLoader('auth'));
        } else if (action.type === AuthActions.LOGOUT) {
            /** TODO => call api to expire token */
            yield fork(setAuthToken, null);
            yield put(authClearStore());
            yield put(navGoTo(routes.login));
            yield put(navClearStore());
        } else if (action.type === AuthActions.CHANGE_PASSWORD) {
            yield put(showLoader('auth'));
            yield call(usersApiRequest, {
                method: 'POST',
                url: `/user/change-password`,
                payload: action.payload
            });
            yield put(
                showSnackbar({
                    opened: true,
                    data: {
                        message: 'Password updated',
                        type: 'success'
                    }
                })
            );
            yield put(hideLoader('auth'));
        } else if (action.type === AuthActions.SSO_LOGIN) {
            yield put(showLoader('auth'));

            const url = action.payload.community === ShowType.HUDDLE ? '/user/sso?isHuddle=true' : '/user/sso';

            const response = yield call(usersApiRequest, {
                method: 'POST',
                url: url,
                payload: action.payload
            });
            const { accessToken } = response.data;
            yield call(setAuthToken, accessToken);

            const nPResponse = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/definition/permissions`
            });
            const hPResponse = yield call(quizzesApiRequest, {
                method: 'GET',
                url: `/game/definition/permissions?isHuddle=true`
            });

            yield put(authSuccess({
                ...response.data,
                community: action.payload.community,
                normalPermission: nPResponse.data,
                huddlePermission: hPResponse.data
            }));
            yield put(hideLoader('auth'));
        }
    } catch (error) {
        const uiError = handleGenerateError(error);
        console.error('AUTH Generated uiError: ', uiError);
        yield put(
            showSnackbar({
                opened: true,
                data: {
                    message: uiError.message,
                    type: 'error'
                }
            })
        );
        yield put(hideLoader('auth'));
    }
}
