/** -> Quizzes Action Types */
export enum QuizzesTypes {
    GET = '[quizes] GET',
    GET_QUIZ = '[quizes] GET_QUIZ',
    GET_QUIZ_PREVIEW = '[quizes] GET_QUIZ_PREVIEW',
    CREATE = '[quizes] CREATE',
    UPDATE = '[quizes] UPDATE',
    UPDATE_PENDING = '[quizes] UPDATE_PENDING',
    UPDATE_PENDING_REWARDS = '[quizes] UPDATE_PENDING_REWARDS',
    GET_GAMECODE = '[quizes] GET_GAMECODE',
    GET_PERMISSON = '[quizes] GET_PERMISSON',
    CLEAR_QUIZ_PREVIEW = '[quizes] CLEAR_QUIZ_PREVIEW',

    DELETE = '[quizes] DELETE',
    ENABLE = '[quizes] ENABLE',
    DISABLE = '[quizes] DISABLE',
    GET_ALL_CATEGORIES = '[quizes] GET_ALL_CATEGORIES',
    GET_CATEGORIES = '[quizes] GET_CATEGORIES',
    CREATE_CAT = '[quizes] CREATE_CATEGORY',
    UPDATE_CAT = '[quizes] UPDATE_CATEGORY',
    DELETE_CAT = '[quizes] DELETE_CATEGORY',
    GET_QUESTIONS = '[quizes] GET_QUESTIONS',
    CREATE_QUESTION = '[quizes] CREATE_QUESTION',
    UPDATE_QUESTION = '[quizes] UPDATE_QUESTION',
    CREATE_REWARD = '[quizes] CREATE_REWARD',
    UPDATE_REWARD = '[quizes] UPDATE_REWARD',
    DELETE_QUESTION = '[quizes] DELETE_QUESTION',
    DELETE_REWARD = '[quizes] DELETE_REWARD',
    REORDER_QUESTIONS = '[quizes] REORDER_QUESTIONS',
    SET = '[quizes] SET',
    SET_ONE = '[quizes] SET_ONE',
    SET_PREVIEW = '[quizes] SET_PREVIEW',
    SET_QUERY_PARAMS = '[quizes] SET_QUERY_PARAMS',
    SET_CATEGORIES = '[quizes] SET_CATEGORIES',
    SET_CAT_QUERY_PARAMS = '[quizes] SET_CATEGORIES_QUERY_PARAMS',
    SET_QUESTIONS = '[quizes] SET_QUESTIONS',
    SET_PENDING = '[quizes] SET_PENDING',
    SET_PENDING_REWARDS = '[quizes] SET_PENDING_REWARDS',
    SET_VISIBILITY = '[quizes] SET_VISIBILITY',
    SET_PRIZES = '[quizes] SET_PRIZES',
    SET_AVATAR = '[quizes] SET_AVATAR',
    SET_TEMP_IMAGE = '[quizes] SET_IMAGE',
    SET_GAMECODE = '[quizes] SET_GAMECODE',
    SET_PERMISSON = '[quizes] SET_PERMISSON',
    SET_PERMISSON_UPDATE = '[quizes] SET_PERMISSION_UPDATE',
    SET_REWARDS = '[quizes] SET_REWARDS',
    COPY_QUIZ = '[quizes] COPY_QUIZ',
    CLEAR_QUIZ = '[quizes] CLEAR_QUIZ',
    SET_QUESTION_IMAGE = '[quizes] SET_QUIZ_QUESTION_IMAGE',
    SET_QUESTION_IMAGE_URL = '[quizes] SET_QUIZ_QUESTION_IMAGE_URL',
    CLEAR_QUESTION_URLS = '[quizes] CLEAR_QUESTION_URLS'
}