import React from 'react';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core';
import { ReactComponent as ComcastSvg } from '../../../assets/img/comcast.svg';
import { ReactComponent as XfinitySvg } from '../../../assets/img/xfinity-communities.svg';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.backgroundColors?.white,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            borderRight: 'none',
            borderBottom: `1px solid ${theme.border?.row}`,
            zIndex: 3,
            padding: 8,
            [theme.breakpoints.up('sm')]: {
                width: 72,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRight: `1px solid ${theme.border?.row}`,
                borderBottom: 'none'
            }
        },
        buttonContainer: {
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 0,
            height: 48,
            width: 48,
            borderRadius: 10,
            border: `2px solid ${theme.backgroundColors?.white}`,
            backgroundColor: theme.backgroundColors?.white,
            '& .selected': {
                border: `2px solid ${theme.border?.selected}`,
                backgroundColor: theme.backgroundColors?.primaryPurple
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: 34
            }
        }
    });

interface Props extends WithStyles<typeof styles> {}
const EnvNav: React.FC<Props> = ({ classes }) => {
    return (
        <div className={classes.root}>
            <div className={classes.buttonContainer}>
                <XfinitySvg className="selected" title="Xfinity" />
            </div>
            <div className={classes.buttonContainer}>
                <ComcastSvg title="Comcast" />
            </div>
        </div>
    );
};

export default withStyles(styles)(EnvNav);
