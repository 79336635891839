import { Users, UsersActions } from '../../types/Users';

const initialState: Users.TState = {
    data: [],
    totalElementsCount: 0,
    totalPagesCount: 0,
    tableQueryParams: {
        searchTerm: '',
        pageSize: 10,
        pageNumber: 0,
        sortBy: '',
        sortDirection: ''
    }
};

const usersReducer = (state = initialState, action: Users.TActions.AllReducer): Users.TState => {
    switch (action.type) {
        case UsersActions.SET:
            return {
                ...state,
                data: action.payload.elements,
                totalElementsCount: action.payload.totalElementsCount,
                totalPagesCount: action.payload.totalPagesCount
            };

        case UsersActions.SET_QUERY_PARAMS:
            return {
                ...state,
                tableQueryParams: {
                    ...state.tableQueryParams,
                    ...action.payload
                }
            };

        default:
            return state;
    }
};

export default usersReducer;
