import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import EventPreview, { EventPreviewProps } from 'Components/Scheduler/components/EventPreview';
import { meetingResponseToProps } from 'Components/Scheduler/redux/mapper';
import useStyles from './styles';
import { getMeeting } from 'redux/actions';
import { meetingsListSelector } from 'Components/Scheduler/redux';

const PreviewMeetingPage: React.FC<RouteComponentProps<{ id?: string }>> = ({match: { params: {id = ''} } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, meeting } = useSelector(meetingsListSelector);
  const [meetingProps, setMeetingProps] = useState<EventPreviewProps>();

  useEffect(() => {
    dispatch(getMeeting(id, true));
  }, [id, dispatch]);

  useEffect(() => {
    if (!loading && meeting) {
      const prop = meetingResponseToProps(meeting, true);
      setMeetingProps(prop);
    }
  }, [loading, meeting]);

  return (
    <div className={classes.root}>
      {meetingProps &&
        <EventPreview
          id={meetingProps.id}
          code={meetingProps.code}
          heroImageData={meetingProps.heroImageData}
          logoImageData={meetingProps.logoImageData}
          eventName={meetingProps.eventName}
          eventDescription={meetingProps.eventDescription}
          backgroundColor={meetingProps.backgroundColor}
          textColor={meetingProps.textColor}
          accentColor={meetingProps.accentColor}
          buttonTextColor={meetingProps.buttonTextColor}
          provider={meetingProps.provider}
          selectedDate={meetingProps.selectedDate}
          selectedTime={meetingProps.selectedTime}
          selectedDuration={meetingProps.selectedDuration}
          selectedTimeZone={meetingProps.selectedTimeZone}
          participants={meetingProps.participants}
          previewShow={true}
        />
      }
      {loading &&
        <h2>
          Loading data...
        </h2>
      }
      {(!meetingProps && !loading) &&
        <h2>
          This link has been expired. Please contact admin.
        </h2>
      }
    </div>
  )
};

export default PreviewMeetingPage;
