import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './index.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

import store from './redux/store';

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        border?: {
            row: React.CSSProperties['color'];
            input: React.CSSProperties['color'];
            selected: React.CSSProperties['color'];
        };
        backgroundColors?: {
            white: React.CSSProperties['color'];
            black: React.CSSProperties['color'];

            primary: React.CSSProperties['color'];
            primaryBlue: React.CSSProperties['color'];
            primaryPurple: React.CSSProperties['color'];
            primaryRed: React.CSSProperties['color'];
            primaryGreen: React.CSSProperties['color'];
            primaryGrey: React.CSSProperties['color'];
            primaryLight: React.CSSProperties['color'];
            secondary: React.CSSProperties['color'];
            secondaryBlue: React.CSSProperties['color'];
            secondaryGrey: React.CSSProperties['color'];
            tertiaryBlue: React.CSSProperties['color'];
            tertiaryGrey: React.CSSProperties['color'];
        };
        text?: {
            white: React.CSSProperties['color'];
            black: React.CSSProperties['color'];

            primary: React.CSSProperties['color'];
            primaryBlue: React.CSSProperties['color'];
            primaryPurple: React.CSSProperties['color'];
            primaryRed: React.CSSProperties['color'];
            primaryGreen: React.CSSProperties['color'];
            secondary: React.CSSProperties['color'];
            secondaryBlue?: React.CSSProperties['color'];
        };
    }
    interface ThemeOptions {
        border?: {
            row: React.CSSProperties['color'];
            input: React.CSSProperties['color'];
            selected: React.CSSProperties['color'];
        };
        backgroundColors?: {
            white: React.CSSProperties['color'];
            black: React.CSSProperties['color'];
            primary: React.CSSProperties['color'];
            primaryBlue: React.CSSProperties['color'];
            primaryPurple: React.CSSProperties['color'];
            primaryRed: React.CSSProperties['color'];
            primaryGreen: React.CSSProperties['color'];
            primaryGrey: React.CSSProperties['color'];
            primaryLight: React.CSSProperties['color'];
            secondary: React.CSSProperties['color'];
            secondaryBlue: React.CSSProperties['color'];
            secondaryGrey: React.CSSProperties['color'];
            tertiaryBlue: React.CSSProperties['color'];
            tertiaryGrey: React.CSSProperties['color'];
        };
        text?: {
            white: React.CSSProperties['color'];
            black: React.CSSProperties['color'];
            primary: React.CSSProperties['color'];
            primaryBlue: React.CSSProperties['color'];
            primaryPurple: React.CSSProperties['color'];
            primaryRed: React.CSSProperties['color'];
            primaryGreen: React.CSSProperties['color'];
            secondary: React.CSSProperties['color'];
            secondaryBlue: React.CSSProperties['color'];
        };
    }
}

const appTheme = createMuiTheme({
    typography: {
        fontSize: 13,
        htmlFontSize: 13,
        fontFamily: 'inherit',
        body1: {
            fontSize: 13
        },
        h1: {
            fontSize: 24
        },
        h2: {
            fontSize: 20
        }
    },
    backgroundColors: {
        black: '#000000', // Black: #000000
        white: '#ffffff', // White: #FFFFFF
        /** Old UX */
        // primary: '#F6F4EE', // Beige: #F6F4EE
        primaryBlue: '#425FBB', // Blue: #425FBB
        primaryPurple: '#94A6E7', // Light Purple: #94A6E7
        primaryRed: '#E01E4A', // Red: #E01E4A
        primaryGreen: '#00D267', // Green: #00D267
        primaryGrey: '#F7F7F7', // Light Gray: #F7F7F7
        primaryLight: '#F2F2F2',
        // secondary: '#EEECE4', // Dark Beige: #EEECE4
        // secondaryBlue: '#ECF1FF', // Light Blue: #ECF1FF
        secondaryGrey: '#9C9C9C', // Gray: #9C9C9C
        tertiaryBlue: '#94A6E7',
        tertiaryGrey: '#DDDDDD',
        /** New UX */
        primary: '#FFFFFF', // White: #FFFFFF
        secondary: '#FFFFFF', // White: #FFFFFF
        secondaryBlue: '#F3F3F7', // Secondary color/blue 01: ##F3F3F7
    },
    border: {
        row: '#E4E4E4',
        input: '#E3E1D9',
        selected: '#94A6E7'
    },
    text: {
        black: '#000000', // Black: #000000
        white: '#ffffff', // White: #FFFFFF
        /**New UX */
        primary: '#1F1F1F',
        primaryBlue: '#425FBB', // Blue: #425FBB
        primaryPurple: '#94A6E7', // Light Purple: #94A6E7
        primaryRed: '#E01E4A', // Red: #E01E4A
        primaryGreen: '#00D267', // Green: #00D267
        secondary: '#9C9C9C', // Gray: #9C9C9C
        secondaryBlue: '#ECF1FF' // Light Blue: #ECF1FF
    }, //303f9f/3f51b5
    palette: {
        primary: {
            main: '#425FBB'
        },
        secondary: {
            main: '#94A6E7'
        },
        success: {
            main: '#00D267'
        },
        error: {
            main: '#E01E4A'
        }
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#fff',
                borderColor: '#DDDDDD',
                borderRadius: 10,
                color: '#1F1F1F'
            },
            input: {
                '&::placeholder': {
                    color: '#9C9C9C',
                    opacity: 1,
                    fontWeight: 500
                },
                color: '#425FBB',
                fontWeight: 500,
                '&.Mui-disabled': {
                    color: '#9C9C9C'
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 10,
                textTransform: 'none'
            },
            contained: {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8
            },
            outlined: {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                borderWidth: '2px !important'
            },
            text: {
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8
            }
        }
    }
});

ReactDOM.render(
    <ThemeProvider theme={appTheme}>
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <App />
            </DndProvider>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
