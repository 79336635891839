import React from 'react';
import { withStyles, createStyles, Theme, WithStyles, CircularProgress } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        overlay: {
            backgroundColor: theme.backgroundColors?.primary,
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.text?.primary
        },
        progress: {
            color: theme.text?.primary
        }
    });

interface Props extends WithStyles<typeof styles> {
    text?: string;
}

const Loader: React.FC<Props> = ({ classes, text = 'Loading' }) => {
    return (
        <div className={classes.overlay}>
            <div className={classes.root}>
                {text + '...'}
                <CircularProgress className={classes.progress} />
            </div>
        </div>
    );
};

export default withStyles(styles)(Loader);
