const getFileInfo = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }


    const blob = new Blob([ab], {type: mimeString});

    const reader = new FileReader()

    return reader.readAsDataURL(blob)
}

export const urlToFile = (url: string, filename: string, mimeType: string) : Promise<File> => {
    return fetch(url)
        .then(res => {
            return res.arrayBuffer();
        })
        .then(buf => {
            return new File([buf], filename, {type: mimeType});
        })
}

//Usage example:
urlToFile('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'hello.txt', 'text/plain')
    .then((file) => {
        console.log(file);
    });

export default getFileInfo