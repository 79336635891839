import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 70
  }
}))