import React, { useState, useCallback } from 'react';
import { Typography, Button, Grid, Modal, Backdrop, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import useStyles from './styles';
import OutlinedTextFields from '../OutlinedTextField';

export type RSVPModalProps = {
  open: boolean,
  onClose: () => void,
  onRSVP: (firstName: string, lastName: string, email: string) => void
}

const RSVPModal: React.FC<RSVPModalProps> = (({
  open,
  onClose,
  onRSVP
}) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const onChangeFirstName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  }, []);

  const onChangeLastName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  }, []);

  const onChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  return (
    <Modal open={open} BackdropComponent={Backdrop}>
      <div className={classes.root}>
        <IconButton className={classes.closeButton} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h2">RSVP to join the event!</Typography>
        <Typography></Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <OutlinedTextFields value={firstName} label="First Name" multiline={false} onChange={onChangeFirstName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedTextFields value={lastName} label="Last Name" multiline={false} onChange={onChangeLastName} />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextFields value={email} label="Email Address" multiline={false} onChange={onChangeEmail} />
          </Grid>
        </Grid>
        <div className={classes.actions}>
          <Button variant="contained" color="primary" onClick={() => onRSVP(firstName, lastName, email)}>
            Join Us
          </Button>
        </div>
      </div>
    </Modal>
  )
})

export default RSVPModal;