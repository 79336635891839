import { combineReducers } from 'redux';
import { IAppState } from '../../types';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import modalReducer from './modalReducer';
import uiReducer from './uiReducer';
import quizzesReducer from "../../Components/Quiz/redux";
import meetingsReducer from 'Components/Scheduler/redux';

export const reducers = combineReducers<IAppState>({
    auth: authReducer,
    users: usersReducer,
    quizzes: quizzesReducer,
    meetings: meetingsReducer,
    modal: modalReducer,
    ui: uiReducer
});
