import { Auth, AuthActions } from '../../types/Auth';

/**
 * Auth Redux Action
 * @param data {email, password}
 */
export const authLogin = (data: Auth.TCredentials): Auth.TActions.Login => ({
    type: AuthActions.LOGIN,
    payload: data
});

export const ssoLogin = (data: Auth.TSSO): Auth.TActions.SSOLogin => ({
    type: AuthActions.SSO_LOGIN,
    payload: data
});

/**
 * Auth Redux Action
 * @param data { user, accessToken, accessTokenExpiresOn }
 */
export const authSuccess = (data: Auth.TAuthAPIResponse): Auth.TActions.LoginSuccess => ({
    type: AuthActions.SUCCESS,
    payload: data
});

/**
 * Auth Redux Action
 */
export const authRefreshToken = (): Auth.TActions.RefreshToken => ({
    type: AuthActions.REFRESH_TOKEN
});

/**
 * Auth Redux Action
 */
export const authLogout = (): Auth.TActions.Logout => ({
    type: AuthActions.LOGOUT
});

/**
 * Auth Redux Action
 */
export const authClearStore = (): Auth.TActions.Clear => ({
    type: AuthActions.CLEAR
});

/**
 * Auth Redux Action
 * @param data {currentPassword, cewPassword}
 */
export const authChangePassword = ( data: Auth.TChangePassPayload ): Auth.TActions.ChangePassword => ({
    type: AuthActions.CHANGE_PASSWORD,
    payload: data
});
