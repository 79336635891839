import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { useLocation } from 'react-router-dom';
import {
    useTheme,
    MenuItem,
    makeStyles,
    Theme,
    // IconButton,
    OutlinedInput,
    Button,
    ListItem,
    IconButton,
    Tooltip
} from '@material-ui/core';
import { Visibility as EyeIcon } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import RowMenu from './components/RowMenu';
import Loader from './Loader';
import DateCell from './components/DateCell';
import ChipCell from './components/ChipCell';

import { Quizzes, QuizStates } from '../../../types/Quizzes';

import { TABLE_ICONS } from './config';
import { GameDefinitionState, RolesEnum } from 'types/Enums';
import { Routes } from 'Utils/RouteBuilder';
import { useSelector } from 'react-redux';
import { userInfoSelector } from 'Components/Quiz/redux/selectors';
import { Auth } from 'types/Auth';

const useChipStyles = makeStyles((theme: Theme) => ({
    [QuizStates.Active]: {
        backgroundColor: theme.backgroundColors?.primaryGreen,
        color: theme.text?.white
    },
    [QuizStates.Disabled]: {
        backgroundColor: theme.backgroundColors?.secondaryGrey,
        color: theme.text?.white
    },
    [QuizStates.SoftDeleted]: {
        backgroundColor: theme.backgroundColors?.primaryPurple,
        color: theme.text?.white
    }
}));
interface IQuizzesTableProps {
    data: Quizzes.TQuizTableRecord[];
    totalCount: number;
    pageNumber?: number;
    pageSize: number;
    sortBy: Quizzes.TQueryParams['sortBy'];
    sortDirection: Quizzes.TQueryParams['sortDirection'];
    isLoading: boolean;
    selection?: boolean;
    permission: Auth.TPermission,
    handleEditQuiz: (user: any) => void;
    handleDuplicateQuiz: (id: string) => void;
    handleToggleStatus: (id: string, status: Quizzes.TDefinition['gameDefinitionState']) => void;
    handleRemoveQuiz: (id: string) => void;
    handleQueryParams: (params: any) => void;
    handleSelectionChange: (quizzes: any[]) => void;
    handlePreview: (id: string) => void;
    handleStart: (id: string) => void;
}

const QuizzesTable: React.FC<IQuizzesTableProps> = ({
    data,
    totalCount,
    pageSize,
    sortBy,
    sortDirection,
    isLoading,
    selection,
    permission,
    handleEditQuiz,
    handleDuplicateQuiz,
    handleToggleStatus,
    handleRemoveQuiz,
    handleQueryParams,
    handleSelectionChange,
    handlePreview,
    handleStart
}) => {
    const theme = useTheme();
    const chipCellClasses = useChipStyles();
    const location = useLocation();

    const [huddle, setHuddle] = useState(false);
    const { user } = useSelector(userInfoSelector);
    const [copied, setCopied] = useState('')

    const [colFilterState, setColFilterState] = useState<{
        anchor: HTMLElement | null;
        open: boolean;
        field: string;
        term: string;
    }>({ anchor: null, open: false, field: '', term: '' });
    const [rowMenuState, setRowMenuState] = useState<{
        anchor: HTMLElement | null;
        open: boolean;
        rowData: Quizzes.TQuizTableRecord | null;
    }>({ anchor: null, open: false, rowData: null });
    const cellStyle = {
        borderBottomColor: theme.border?.row
    };
    const renderCreatedCell = (rowData: Quizzes.TQuizTableRecord) => (
        <DateCell date={rowData.createOn} />
    );
    const renderhipCell = (rowData: Quizzes.TQuizTableRecord) => {
        const statusClass = chipCellClasses[rowData.status];
        return <ChipCell label={rowData.status} classes={{ root: statusClass }} />;
    };

    const renderPreviewCell = (rowData: Quizzes.TQuizTableRecord) => {
        if (huddle) {
            return (
                <Tooltip title="Preview">
                <IconButton color="primary" onClick={() => handlePreview(rowData.id)}>
                    <EyeIcon />
                </IconButton>
                </Tooltip>
            )
        } else {
            return (<></>);
        }
    }

    const renderStartCell = (rowData: Quizzes.TQuizTableRecord) => {
        if (huddle) {
            return (
                <Button variant="outlined" color="primary" disabled={rowData.status !== GameDefinitionState.Active} onClick={() => { handleStart(rowData.id) }}>
                    Start Trivia
                </Button>
            )
        } else {
            return (<></>);
        }
    }

    const handleCopyQueryLink = (id: string) => {
        const textArea = document.createElement("textarea");
        textArea.value = `${window.location.origin}/query/?trivio=${id}`;
        textArea.style.top = "-1000px";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        setCopied(id)
        setTimeout(() => {
            setCopied('')
        }, 1500)
    }

    const renderQueryLink = (rowData: Quizzes.TQuizTableRecord) => {
        if (huddle) {
            return copied === rowData.id ? <>Link Copied</> : (
                <Tooltip title="Copy Link to this Trivio">
                    <div
                        style={{color: 'blue', cursor: 'pointer'}} 
                        onClick={() => handleCopyQueryLink(rowData.id)}
                    >
                        {rowData.id}
                    </div>
                </Tooltip>
            )
        } else {
            return <>{rowData.id}</>;
        }
    }

    const columnsDef = [
        { title: 'Quiz Name', field: 'name', sortField: 'name', cellStyle },
        {
            // title: (
            //     <>
            //         <IconButton
            //             onClick={(e) => {
            //                 e.stopPropagation();
            //                 setColFilterState({
            //                     anchor: e.currentTarget as HTMLElement,
            //                     open: true,
            //                     field: 'category',
            //                     term: ''
            //                 });
            //             }}
            //             style={{
            //                 padding: 8,
            //                 marginRight: 8
            //             }}
            //         >
            //             <FilterList color="inherit" style={{ width: 20, height: 20 }} />
            //         </IconButton>
            //         <span>Category</span>
            //     </>
            // ),
            title: 'Category',
            field: 'category.name',
            sortField: 'category.name',
            cellStyle
        },
        {
            title: 'Created on',
            field: 'createOn',
            sortField: 'createdOnUtc',
            cellStyle,
            render: renderCreatedCell
        },
        {
            title: 'Status',
            field: 'status',
            sortField: 'gameDefinitionState',
            cellStyle,
            render: renderhipCell
        },
        { title: 'Quiz Id', field: 'id', sortField: 'id', cellStyle, render: renderQueryLink },
        { title: '', cellStyle, render: renderStartCell },
        { title: '', cellStyle, render: renderPreviewCell }
    ];

    const adminColumnDef = [
        { title: 'Quiz Name', field: 'name', sortField: 'name', cellStyle },
        {
            // title: (
            //     <>
            //         <IconButton
            //             onClick={(e) => {
            //                 e.stopPropagation();
            //                 setColFilterState({
            //                     anchor: e.currentTarget as HTMLElement,
            //                     open: true,
            //                     field: 'category',
            //                     term: ''
            //                 });
            //             }}
            //             style={{
            //                 padding: 8,
            //                 marginRight: 8
            //             }}
            //         >
            //             <FilterList color="inherit" style={{ width: 20, height: 20 }} />
            //         </IconButton>
            //         <span>Category</span>
            //     </>
            // ),
            title: 'Category',
            field: 'category.name',
            sortField: 'category.name',
            cellStyle
        },
        { title: 'Visibility', field: 'visibility', sortField: 'visibility', cellStyle },
        { title: 'Created by', field: 'createdBy.name', sortField: 'createdBy.name', cellStyle },
        {
            title: 'Created on',
            field: 'createOn',
            sortField: 'createdOnUtc',
            cellStyle,
            render: renderCreatedCell
        },
        {
            title: 'Status',
            field: 'status',
            sortField: 'gameDefinitionState',
            cellStyle,
            render: renderhipCell
        },
        { title: 'Quiz Id', field: 'id', sortField: 'id', cellStyle, render: renderQueryLink },
        { title: '', cellStyle, render: renderStartCell },
        { title: '', cellStyle, render: renderPreviewCell }
    ];

    useEffect(() => {
        setHuddle(location.pathname.indexOf(Routes.HUDDLE) > -1);
    }, [location]);

    const onOrderChange = (orderBy: number, orderDirection: 'desc' | 'asc') => {
        if (orderBy >= 0) {
            const sortFiled = (user?.roles === RolesEnum.HuddleGameSuperAdmin || user?.roles === RolesEnum.SuperUser || user?.roles === RolesEnum.AllSuperAdmin ? adminColumnDef : columnsDef)[orderBy].sortField;
            handleQueryParams({ sortBy: sortFiled, sortDirection: orderDirection });
        } else {
            handleQueryParams({ sortBy: '', sortDirection: '' });
        }
    };

    const closeColFilter = () => {
        setColFilterState({
            anchor: null,
            open: false,
            field: '',
            term: ''
        });
    };

    const renderColFilter = () => {
        const { open, anchor, term } = colFilterState;
        return (
            <RowMenu anchorEl={anchor} open={open} onClose={closeColFilter}>
                <ListItem>
                    <OutlinedInput
                        value={term}
                        onChange={(e) => {
                            setColFilterState((prevState) => ({
                                ...prevState,
                                term: e.target.value
                            }));
                        }}
                    />
                </ListItem>
                <ListItem>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleQueryParams({
                                filterBy: {
                                    field: colFilterState.field as Quizzes.TQuizSortFileds,
                                    term: colFilterState.term
                                }
                            });
                            closeColFilter();
                        }}
                    >
                        Search
                    </Button>
                </ListItem>
            </RowMenu>
        );
    };

    const closeMenu = () => {
        setRowMenuState({
            anchor: null,
            open: false,
            rowData: null
        });
    };

    const renderRowMenu = () => {
        const { open, anchor, rowData } = rowMenuState;
        return (
            <RowMenu anchorEl={anchor} open={open} onClose={closeMenu}>
                {(permission.canEdit || (permission.canEditOwn && rowData?.createdBy.id === user?.id)) &&
                    <MenuItem
                        style={{
                            color: theme.text?.primaryBlue,
                            fontWeight: 500
                        }}
                        onClick={() => {
                            handleEditQuiz(rowData!.id);
                            closeMenu();
                        }}
                    >
                        Edit
                    </MenuItem>
                }
                {(permission.canEdit || (permission.canEditOwn && rowData?.createdBy.id === user?.id)) &&
                    <MenuItem
                        style={{
                            color: theme.text?.primaryBlue,
                            fontWeight: 500
                        }}
                        onClick={() => {
                            handleToggleStatus(rowData!.id, rowData!.status);
                            closeMenu();
                        }}
                    >
                        {rowData && QuizStates.Disabled === rowData!.status ? 'Enable' : 'Disable'}
                    </MenuItem>
                }
                {(permission.canCreate) &&
                    <MenuItem
                        style={{
                            color: theme.text?.primaryBlue,
                            fontWeight: 500
                        }}
                        onClick={() => {
                            // handleEditQuiz(rowData!.id);
                            handleDuplicateQuiz(rowData!.id)
                            closeMenu();
                        }}
                    >
                        Duplicate
                    </MenuItem>
                }
                {(permission.canDelete || (permission.canDeleteOwn && rowData?.createdBy.id === user?.id)) &&
                    <MenuItem
                        style={{
                            color: theme.text?.primaryRed,
                            fontWeight: 500
                        }}
                        onClick={() => {
                            handleRemoveQuiz(rowData!.id);
                            closeMenu();
                        }}
                    >
                        Delete
                    </MenuItem>
                }
            </RowMenu>
        );
    };

    const actionPermission = (rowData: Quizzes.TQuizTableRecord) => {
        return permission.canEdit || permission.canDelete || (permission.canEditOwn && rowData.createdBy.id === user?.id) || (permission.canDeleteOwn && rowData.createdBy.id === user?.id);
    };

    return (
        <>
            <MaterialTable
                style={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }}
                icons={TABLE_ICONS}
                isLoading={isLoading}
                columns={(user?.roles === RolesEnum.HuddleGameSuperAdmin || user?.roles === RolesEnum.SuperUser || user?.roles === RolesEnum.AllSuperAdmin ? adminColumnDef : columnsDef).map((cDef) => {
                    if (cDef.sortField === sortBy && sortDirection) {
                        return { ...cDef, defaultSort: sortDirection };
                    }
                    return cDef;
                })}
                data={data}
                totalCount={totalCount}
                onOrderChange={onOrderChange}
                options={{
                    headerStyle: {
                        backgroundColor: 'transparent',
                        borderBottomWidth: 2,
                        paddingBottom: 0,
                        paddingTop: 0,
                        ...cellStyle
                    },
                    actionsCellStyle: {
                        ...cellStyle
                    },
                    filtering: false,
                    toolbar: false,
                    paging: false,
                    selection,
                    draggable: false,
                    columnsButton: false,
                    actionsColumnIndex: -1,
                    pageSize: pageSize
                }}
                components={{
                    OverlayLoading: Loader,
                    Pagination: () => null
                }}
                localization={{
                    header: {
                        actions: 'More'
                    },
                    body: {
                        emptyDataSourceMessage: 'You have no quizzes.'
                    }
                }}
                actions={!selection ? [
                    rowData => (
                        actionPermission(rowData) ?
                        {
                            icon: () => (
                                actionPermission(rowData) ?
                                <FontAwesomeIcon
                                    icon={faEllipsisH}
                                    style={{ width: 20, height: 20 }}
                                    className="MuiSvgIcon-root MuiSvgIcon-colorSecondary"
                                /> : <></>
                            ),
                            position: 'row',
                            tooltip: 'More',
                            onClick: (event, rowData) => {
                                setRowMenuState({
                                    anchor: event.currentTarget as HTMLElement,
                                    open: true,
                                    rowData: rowData as Quizzes.TQuizTableRecord
                                });
                            }
                        } : {
                            icon: () => <></>,
                            onClick: (event, rowData) => {},
                            disabled: true
                        }
                    )
                ] : [
                    {
                        icon: () => (
                            <FontAwesomeIcon
                                icon={faEllipsisH}
                                style={{ width: 20, height: 20 }}
                                className="MuiSvgIcon-root MuiSvgIcon-colorSecondary"
                            />
                        ),
                        position: 'row',
                        tooltip: 'More',
                        onClick: (event, rowData) => {
                            setRowMenuState({
                                anchor: event.currentTarget as HTMLElement,
                                open: true,
                                rowData: rowData as Quizzes.TQuizTableRecord
                            });
                        }
                    }
                ]}
                onSelectionChange={handleSelectionChange}
            />
            {renderRowMenu()}
            {renderColFilter()}
        </>
    );
};

export default QuizzesTable;
