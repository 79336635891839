import React from 'react';
import {
    withStyles,
    createStyles,
    Theme,
    WithStyles,
    useTheme,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons'
import { 
    // faFilter, 
    faSearch
 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const styles = (theme: Theme) =>
    createStyles({
        searchInput: {
            height: 50,
            width: '100%',
            backgroundColor: '#fff',
            borderRadius: 24,
            borderColor: theme.border?.input,
            fontSize: 15,
            color: theme.text?.primary
        },
        adornedEnd: {
            paddingRight: theme.spacing(0.5)
        },
        adorned: {
            fontSize: 20
        },
        filterButton: {
            width: 120,
            borderRadius: 24,
            margin: theme.spacing(0.7),
            backgroundColor: theme.backgroundColors?.secondaryBlue,
            color: theme.text?.primaryBlue
        }
    });

interface Props extends WithStyles<typeof styles> {
    placehoder?: string;
    handleSubmitSearch: (data: string) => void;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleClearSearch: () => void
}

const SearchInput: React.FC<Props> = ({ classes, placehoder = 'Search', handleSubmitSearch, value, onChange, handleClearSearch }) => {
    const theme = useTheme();
    /** TODO => add dropdown with results */
    return (
        <OutlinedInput
            placeholder={placehoder}
            classes={{
                adornedEnd: classes.adornedEnd
            }}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmitSearch(value)
                }
              }}
            className={classes.searchInput}
            onChange={onChange}
            value={value}
            startAdornment={
                <InputAdornment
                    position="start"
                    classes={{
                        root: classes.adorned
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} color={theme.border?.input} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment
                    position="end"
                    classes={{
                        root: classes.adorned
                    }}
                >
                    <IconButton
                        onClick={handleClearSearch}
                    >
                        <CloseIcon style={{color: theme.border?.input}} />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};

export default withStyles(styles)(SearchInput);
