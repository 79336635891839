import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface IPageTitleProps extends Pick<TypographyProps, 'align'> {
    text: string;
    className ?: string
}

const PageTitle: React.FC<IPageTitleProps> = ({ text, align, className }) => {
    return (
        <Typography variant="h1" component="div" align={align} className={className}>
            {text}
        </Typography>
    );
};

export default PageTitle;
