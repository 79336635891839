import { v4 as uuid } from 'uuid';
import { Quizzes } from '../../types/Quizzes';
const REWARD_TEMPLATE_STORE = {
    id: '',
    prizeType: 'Tour Miles',
    coin: '',
    amount: '',
} as Quizzes.TReward;

const storeToState = (reward: Quizzes.TReward): Quizzes.TRewardFormFields => {
    return {
        id: reward.id,
        prizeType: reward.prizeType,
        coin: reward.coin,
        amount: reward.amount
    };
};
const stateToStore = (reward: Quizzes.TRewardFormFields): Quizzes.TReward => {
    return {
        id: reward.id,
        prizeType: reward.prizeType,
        coin: reward.coin,
        amount: reward.amount
    };
};
export const rewardMapper = {
    getStoreTemplate: () => ({ ...REWARD_TEMPLATE_STORE, id: `TEMP_${uuid()}` }),
    getStateTemplate: () => storeToState({ ...REWARD_TEMPLATE_STORE, id: `TEMP_${uuid()}` }),
    storeToState,
    stateToStore,
    getInisitalStateTemplate: () => REWARD_TEMPLATE_STORE,
};
